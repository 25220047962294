import {
	ADD_DOWNLOAD_LINK,
	ADD_DOWNLOAD_LINK_FAIL,
	ADD_DOWNLOAD_LINK_SUCCESS,
	FETCH_DOWNLOAD_LINK,
	FETCH_DOWNLOAD_LINK_FAIL,
	FETCH_DOWNLOAD_LINK_SUCCESS,
	FETCH_FILTER_DOWNLOAD_LINK,
	FETCH_FILTER_DOWNLOAD_LINK_FAIL,
	FETCH_FILTER_DOWNLOAD_LINK_SUCCESS,
	MODIFY_DOWNLOAD_LINK,
	MODIFY_DOWNLOAD_LINK_FAIL,
	MODIFY_DOWNLOAD_LINK_SUCCESS,
} from "../actions";

export const fetchFilterDownloadLink = (currentPage, pageLimit) => ({
	type: FETCH_FILTER_DOWNLOAD_LINK,
	payload: { currentPage, pageLimit },
});

export const fetchFilterDownloadLinkSuccess = (
	filterdownloadLinks,
	totalPages,
	currentpage
) => ({
	type: FETCH_FILTER_DOWNLOAD_LINK_SUCCESS,
	payload: { filterdownloadLinks, totalPages, currentpage },
});

export const fetchFilterDownloadLinkFail = (message) => ({
	type: FETCH_FILTER_DOWNLOAD_LINK_FAIL,
	payload: { message },
});

export const fetchDownloadLink = (currentPage, pageLimit) => ({
	type: FETCH_DOWNLOAD_LINK,
	payload: { currentPage, pageLimit },
});

export const fetchDownloadLinkSuccess = (
	downloadLinks,
	totalPages,
	currentpage
) => ({
	type: FETCH_DOWNLOAD_LINK_SUCCESS,
	payload: { downloadLinks, totalPages, currentpage },
});

export const fetchDownloadLinkFail = (message) => ({
	type: FETCH_DOWNLOAD_LINK_FAIL,
	payload: { message },
});

export const addDownloadLink = (downloadData) => ({
	type: ADD_DOWNLOAD_LINK,
	payload: { downloadData },
});

export const addDownloadLinkFail = (message) => ({
	type: ADD_DOWNLOAD_LINK_FAIL,
	payload: { message },
});

export const addDownloadLinkSuccess = (message) => ({
	type: ADD_DOWNLOAD_LINK_SUCCESS,
	payload: { message },
});

export const modifyDownloadLink = (modifyDownloadLinkData) => ({
	type: MODIFY_DOWNLOAD_LINK,
	payload: { modifyDownloadLinkData },
});

export const modifyDownloadLinkFail = (message) => ({
	type: MODIFY_DOWNLOAD_LINK_FAIL,
	payload: { message },
});

export const modifyDownloadLinkSuccess = (message) => ({
	type: MODIFY_DOWNLOAD_LINK_SUCCESS,
	payload: { message },
});
