import { FETCH_COUNTRY } from "../actions";

const INIT_STATE = { countries: [] };

const country = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_COUNTRY:
			console.log("countryreducer", action);
			return {
				// ...state,
				countries: action.countries,
			};
		default:
			return state;
	}
};

export default country;
