import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import axios from "axios";
import {
	FETCH_AEPS_COMMISION,
	FETCH_DMT_COMMISION,
	FETCH_DTH_COMMISION,
	FETCH_FILTER_DISTRIBTUOR,
	FETCH_ELECTRICITY_COMMISION,
	FETCH_MOBILE_POSTPAID_COMMISION,
	FETCH_MOBILE_PREPAID_COMMISION,
	FETCH_PAN_COMMISION,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR,
	ADD_DISTRIBUTERS,
	FETCH_DISTRIBUTORS_LIST,
	VIEW_DISTRIBUTOR_DETAIL,
	MODIFY_DISTRIBUTOR,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR,
} from "../actions";
import {
	fetchAEPSCommision,
	fetchElectricityCommission,
	fetchDMTCommision,
	fetchDTHCommision,
	fetchMobilePostpaidCommission,
	fetchMobilePrepaidCommission,
	fetchPanCommission,
	fetchRetailerUnderDistributorFail,
	fetchRetailerUnderDistributorSuccess,
	fetchFilterDistributorFail,
	fetchFilterDistributorSuccess,
	addDistributorFail,
	addDistributorSuccess,
	fetchDistributorsListSuccess,
	fetchDistributorDetail,
	modifyDistributorSuccess,
	modifyDistributorFail,
	uploadProfilePicFail,
	uploadProfilePicSuccess,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

/*************ADD DISTRIBUTOR********/
export function* watchAddDistributor() {
	yield takeEvery(ADD_DISTRIBUTERS, addNewDistribuorFunc);
}

const newDistributor = async (
	name,
	email,
	primary_mobile,
	secondary_mobile,
	password,
	gender,
	dob,
	address,
	town,
	tehsil,
	district,
	state,
	country,
	pincode,
	gstin,
	pan,
	aadhar
) => {
	try {
		const response = await axios.post(
			`${API_URL}users/distributors/register`,
			// { AuthToken },
			{
				name,
				email,
				primary_mobile,
				secondary_mobile,
				password,
				gender,
				dob,
				address,
				town,
				tehsil,
				district,
				state,
				country,
				pincode,
				gstin,
				pan,
				aadhar,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* addNewDistribuorFunc({ payload }) {
	const {
		name,
		email,
		primary_mobile,
		secondary_mobile,
		password,
		gender,
		dob,
		address,
		town,
		tehsil,
		district,
		state,
		country,
		pincode,
		gstin,
		pan,
		aadhar,
	} = payload.distributorValues;
	try {
		const addDistributorResponse = yield call(
			newDistributor,
			name,
			email,
			primary_mobile,
			secondary_mobile,
			password,
			gender,
			dob,
			address,
			town,
			tehsil,
			district,
			state,
			country,
			pincode,
			gstin,
			pan,
			aadhar
		);

		if (addDistributorResponse.response) {
			if (addDistributorResponse.response.data.type === 2) {
				yield put(
					addDistributorFail(
						addDistributorResponse.response.data.message
					)
				);
			} else if (addDistributorResponse.response.data.type === 1) {
				yield put(
					addDistributorFail(
						addDistributorResponse.response.data.message
					)
				);
			} else {
				yield put(
					addDistributorFail(
						addDistributorResponse.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				addDistributorSuccess(
					addDistributorResponse.data.data.description
				)
			);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		}
	} catch (error) {
		yield put(addDistributorFail(error));
	}
}

/***************UPLOAD PROFILE PIC**************/
export function* watchUploadProfilePic() {
	yield takeEvery(UPLOAD_PROFILE_PIC_DISTRIBUTOR, uploadProfilePic);
}

const newUploadProfilePic = async (distributor_image) => {
	try {
		const response = await axios.post(
			`${API_URL}users/distributors/my/profile/image/upload`,
			// { AuthToken },
			{
				distributor_image,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* uploadProfilePic({ payload }) {
	console.log(payload);
	const { distributor_image } = payload.profilePic;
	// const { history } = payload;
	try {
		const uploadProfilePicvar = yield call(
			newUploadProfilePic,
			distributor_image
		);

		if (uploadProfilePicvar.response) {
			if (uploadProfilePicvar.response.data.type === 2) {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.message
					)
				);
			} else if (uploadProfilePicvar.response.data.type === 1) {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.message
					)
				);
			} else {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				uploadProfilePicSuccess(
					uploadProfilePicvar.data.data.description
				)
			);
			// setTimeout(function () {
			// 	history.push("/admin/basecommissions/aeps_commisions");
			// }, 3000);
		}
	} catch (error) {
		yield put(uploadProfilePicFail(error));
	}
}
/*************LOAD AEPS COMMISSION*******/
export function* watchLoadAEPSCommision() {
	while (true) {
		yield take(FETCH_AEPS_COMMISION);
		yield call(loadAEPSCOmmision);
	}
}

const getAEPSCOmmision = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/aeps`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadAEPSCOmmision() {
	try {
		const AEPSCOmmision = yield call(getAEPSCOmmision);
		if (AEPSCOmmision) {
			yield put(fetchAEPSCommision(AEPSCOmmision));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/**************LOAD DMT COMMISSIONS***/
export function* watchLoadDMTCommision() {
	while (true) {
		yield take(FETCH_DMT_COMMISION);
		yield call(loadDMTCommission);
	}
}

const getDMTCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/dmt`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadDMTCommission() {
	try {
		const DMTCommission = yield call(getDMTCommission);
		if (DMTCommission) {
			yield put(fetchDMTCommision(DMTCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/**************LAOD DTH COMMISSION*****/
export function* watchLoadDTHCommision() {
	while (true) {
		yield take(FETCH_DTH_COMMISION);
		yield call(loadDTHCommission);
	}
}

const getDTHCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/dth`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadDTHCommission() {
	try {
		const DTHCommission = yield call(getDTHCommission);
		if (DTHCommission) {
			yield put(fetchDTHCommision(DTHCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/****************LOAD ELECTRICITY COMMISION***********/
export function* watchLoadElectricityCommision() {
	while (true) {
		yield take(FETCH_ELECTRICITY_COMMISION);
		yield call(loadElectrictyCommission);
	}
}

const getElectricityCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/electricity`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadElectrictyCommission() {
	try {
		const ElectricityCommission = yield call(getElectricityCommission);
		if (ElectricityCommission) {
			yield put(fetchElectricityCommission(ElectricityCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************LOAD MOBILE POSTPAID COMMISSION*********/
export function* watchLoadMobilePostpaidCommision() {
	while (true) {
		yield take(FETCH_MOBILE_POSTPAID_COMMISION);
		yield call(loadMobilePostpaidCommission);
	}
}

const getMobilePostpaidCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/mobile_postpaid`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadMobilePostpaidCommission() {
	try {
		const MobilePostpaidCommission = yield call(
			getMobilePostpaidCommission
		);
		if (MobilePostpaidCommission) {
			yield put(fetchMobilePostpaidCommission(MobilePostpaidCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/****************LOAD MOBILE PREPAID COMMISSION**********/
export function* watchLoadMobilePrepaidCommision() {
	while (true) {
		yield take(FETCH_MOBILE_PREPAID_COMMISION);
		yield call(loadMobilePrepaidCommission);
	}
}

const getMobilePrepaidCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/mobile_prepaid`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadMobilePrepaidCommission() {
	try {
		const MobilePrepaidCommission = yield call(getMobilePrepaidCommission);
		if (MobilePrepaidCommission) {
			yield put(fetchMobilePrepaidCommission(MobilePrepaidCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************LOAD PAN COMMISSION*****/
export function* watchLoadPanCommission() {
	while (true) {
		yield take(FETCH_PAN_COMMISION);
		yield call(loadPanCommission);
	}
}

const getPanCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/distributors/my/pan`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadPanCommission() {
	try {
		const PanCommission = yield call(getPanCommission);
		if (PanCommission) {
			yield put(fetchPanCommission(PanCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/**************LOAD DISTRIBUTOR********/
export function* watchLoadDistributors() {
	yield takeEvery(FETCH_DISTRIBUTORS_LIST, loadDistributors);
}

const getDistributors = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}users/all/distributors/list?limit=${pageLimit}&page_no=${currentPage}`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadDistributors({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const distributors = yield call(
			getDistributors,
			currentPage,
			pageLimit
		);
		if (distributors) {
			yield put(
				fetchDistributorsListSuccess(
					distributors[0].distributors,
					distributors[0].numberOfPages,
					distributors[0].currentPageNumber
				)
			);
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************FILTER DISTRIBUTOR*******************/
export function* watchFilterdistributor() {
	yield takeEvery(FETCH_FILTER_DISTRIBTUOR, loadFilterDistributor);
	// yield call(loadFilterDistributor);
}

const getFilterDistributor = async (distributorId) => {
	try {
		const response = await axios.get(
			`${API_URL}users/all/distributors/list?id=${distributorId}`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

function* loadFilterDistributor({ payload }) {
	const { distributorId } = payload;
	try {
		const filterDistributor = yield call(
			getFilterDistributor,
			distributorId
		);
		if (filterDistributor) {
			yield put(fetchFilterDistributorSuccess(filterDistributor));
		} else {
			yield put(fetchFilterDistributorFail("something is wrong"));
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*******************FILTER RETAILER UNDER DISTRIBUTOR***********/
export function* watchFilterRetailerUnderDistributor() {
	yield takeEvery(
		FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR,
		loadFilterRetailerUnderDistributor
	);
	// yield call(loadFilterRetailerUnderDistributor);
}

const getFilterRetailerUnderDistributor = async (distributorId) => {
	try {
		const response = await axios.get(
			`${API_URL}users/distributors/my/retailers/list?did=${distributorId}`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

function* loadFilterRetailerUnderDistributor({ payload }) {
	const { distributorId } = payload;
	try {
		const filterRetailerUnderDistributor = yield call(
			getFilterRetailerUnderDistributor,
			distributorId
		);
		if (filterRetailerUnderDistributor) {
			yield put(
				fetchRetailerUnderDistributorSuccess(
					filterRetailerUnderDistributor
				)
			);
		} else {
			yield put(fetchRetailerUnderDistributorFail("something is wrong"));
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************DISTRIBUTOR DETAILS******************/

export function* watchLoadDistributorDetail() {
	while (true) {
		yield take(VIEW_DISTRIBUTOR_DETAIL);
		yield call(loadDistributorDetail);
	}
}

const getDistributorDetail = async () => {
	try {
		const response = await axios.get(
			`${API_URL}users/distributors/my/profile`,
			{ AuthToken }
		);

		return response;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export function* loadDistributorDetail() {
	try {
		const distributorDetail = yield call(getDistributorDetail);
		console.log(distributorDetail);
		if (distributorDetail) {
			if (distributorDetail.status === 200) {
				yield put(
					fetchDistributorDetail(distributorDetail.data.data.items)
				);
			}
		}
	} catch (error) {
		return error;
	}
}

/*******************MODIFY DISTRIBUTOR SAGA********************/
export function* watchModifyDistributor() {
	yield takeEvery(MODIFY_DISTRIBUTOR, modifyDistributorFunc);
}

const modifyDistributorOperation = async (
	name,
	gender,
	dob,
	address,
	town,
	tehsil,
	district,
	state,
	country,
	pincode
) => {
	try {
		const response = await axios.put(
			`${API_URL}users/distributors/my/profile`,
			{
				name,
				gender,
				dob,
				address,
				town,
				tehsil,
				district,
				state,
				country,
				pincode,
			}
			// {
			// 	params: {
			// 		old_name,
			// 	},
			// 	new_name,
			// 	state,
			// }
		);

		return response;
	} catch (error) {
		return error;
	}
};

export function* modifyDistributorFunc({ payload }) {
	console.log(payload);
	const {
		name,
		gender,
		dob,
		address,
		town,
		tehsil,
		district,
		state,
		country,
		pincode,
	} = payload.data;
	try {
		let modifyDistributorValue = yield call(
			modifyDistributorOperation,
			name,
			gender,
			dob,
			address,
			town,
			tehsil,
			district,
			state,
			country,
			pincode
		);

		if (modifyDistributorValue.response) {
			if (modifyDistributorValue.response.data.type === 0) {
				yield put(
					modifyDistributorFail(
						modifyDistributorValue.response.data.errors[0].message
					)
				);
			} else if (modifyDistributorValue.response.data.type === 1) {
				yield put(
					modifyDistributorFail(
						modifyDistributorValue.response.data.message
					)
				);
			} else {
				yield put(
					modifyDistributorFail(
						modifyDistributorValue.response.data.message
					)
				);
			}
		} else {
			yield put(
				modifyDistributorSuccess(
					modifyDistributorValue.data.data.description
				)
			);
			setTimeout(function () {
				window.location.assign("/distributor/profile/profiledetails");
			}, 2000);
		}
	} catch (error) {
		yield put(modifyDistributorFail(error));
	}
}

export default function* rootSaga() {
	yield all([
		fork(watchLoadDistributors),
		fork(watchLoadDistributorDetail),
		fork(watchModifyDistributor),
		fork(watchAddDistributor),
		fork(watchFilterdistributor),
		fork(watchFilterRetailerUnderDistributor),
		fork(watchLoadAEPSCommision),
		fork(watchLoadDMTCommision),
		fork(watchLoadDTHCommision),
		fork(watchLoadElectricityCommision),
		fork(watchLoadMobilePostpaidCommision),
		fork(watchLoadMobilePrepaidCommision),
		fork(watchLoadPanCommission),
		fork(watchUploadProfilePic),
	]);
}
