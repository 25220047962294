import {
  FETCH_AEPS_COMMISION_RETAILER,
  FETCH_DMT_COMMISION_RETAILER,
  FETCH_DTH_COMMISION_RETAILER,
  FETCH_ELECTRICITY_COMMISION_RETAILER,
  FETCH_MOBILE_POSTPAID_COMMISION_RETAILER,
  FETCH_MOBILE_PREPAID_COMMISION_RETAILER,
  FETCH_PAN_COMMISION_RETAILER,
  FETCH_RETAILER_NOTIFICATION,
  ADD_RETAILER,
  ADD_RETAILER_FAIL,
  ADD_RETAILER_SUCCESS,
  FETCH_RETAILERS_LIST,
  FETCH_RETAILERS_LIST_SUCCESS,
  FETCH_RETAILERS_LIST_FAIL,
  VIEW_RETAILERS_OF_DISTRIBUTORS,
  FETCH_RETAILERS_WALLET,
  FETCH_RETAILER_PROFILE_IMAGE,
  FETCH_RETAILER_PAYMENT_LIST,
  FETCH_RETAILER_PAYMENT_LIST_FAIL,
  FETCH_RETAILER_PAYMENT_LIST_SUCCESS,
  RETAILER_ADD_PAYMENT,
  RETAILER_ADD_PAYMENT_FAIL,
  RETAILER_ADD_PAYMENT_SUCCESS,
  FETCH_RETAILER_DISPUTE_LIST,
  FETCH_RETAILER_DISPUTE_LIST_FAIL,
  FETCH_RETAILER_DISPUTE_LIST_SUCCESS,
  FETCH_RETAILER_DISPUTE_DETAIL,
  FETCH_RETAILER_DISPUTE_DETAIL_FAIL,
  FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS,
  ADD_RETAILER_DISPUTE,
  ADD_RETAILER__DISPUTE_FAIL,
  ADD_RETAILER__DISPUTE_SUCCESS,
  ADD_RETAILER_DISPUTE_COMMENT,
  ADD_RETAILER__DISPUTE__COMMENT_FAIL,
  ADD_RETAILER__DISPUTE__COMMENT_SUCCESS,
  FETCH_RETAILER_UNDER_DISTRIBUTOR,
  FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL,
  FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
} from "../actions";

const INIT_STATE = {
  retailers: [],
  retailersUnderDistributor: [],
  distributoreRetailers: [],
  AEPSCommisionData: [],
  DMTCommision: [],
  DTHCommision: [],
  ElectricityCommission: [],
  MobilePostpaidCommission: [],
  MobilePrepaidCommission: [],
  PanCommission: [],
  walletBalance: [],
  paymentList: [],
  disputeList: [],
  disputeDetail: [],
  notificationValues: [],
  profileImage: "",
  loading: false,
  error: "",
  add_retailer_success_message: "",
  add_payment_success_message: "",
  add_dispute_success_message: "",
  add_dispute_comment_success_message: "",
  totalPages: "",
  currentpage: 1,
};

const retailer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_RETAILER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_RETAILER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        add_retailer_success_message: "",
      };
    case ADD_RETAILER_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        add_retailer_success_message: action.payload.message,
      };
    case ADD_RETAILER_DISPUTE:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ADD_RETAILER__DISPUTE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        add_dispute_success_message: "",
      };
    case ADD_RETAILER__DISPUTE_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        add_dispute_success_message: action.payload.message,
      };
    case ADD_RETAILER_DISPUTE_COMMENT:
      return {
        ...state,
        loading: true,
        error: "",
        add_dispute_comment_success_message: "",
      };
    case ADD_RETAILER__DISPUTE__COMMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        add_dispute_comment_success_message: "",
      };
    case ADD_RETAILER__DISPUTE__COMMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        add_dispute_comment_success_message: action.payload.message,
      };
    case RETAILER_ADD_PAYMENT:
      return {
        ...state,
        loading: true,
        error: "",
        add_payment_success_message: "",
      };
    case RETAILER_ADD_PAYMENT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        add_payment_success_message: "",
      };
    case RETAILER_ADD_PAYMENT_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        add_payment_success_message: action.payload.message,
      };
    case FETCH_AEPS_COMMISION_RETAILER:
      return {
        ...state,
        AEPSCommisionData: action.payload.AEPSCommisionData,
      };
    case FETCH_DMT_COMMISION_RETAILER:
      return {
        ...state,
        DMTCommision: action.payload.DMTCommision,
      };
    case FETCH_DTH_COMMISION_RETAILER:
      return {
        ...state,
        DTHCommision: action.payload.DTHCommision,
      };
    case FETCH_ELECTRICITY_COMMISION_RETAILER:
      return {
        ...state,
        ElectricityCommission: action.payload.ElectricityCommission,
      };
    case FETCH_MOBILE_POSTPAID_COMMISION_RETAILER:
      return {
        ...state,
        MobilePostpaidCommission: action.payload.MobilePostpaidCommission,
      };
    case FETCH_MOBILE_PREPAID_COMMISION_RETAILER:
      return {
        ...state,
        MobilePrepaidCommission: action.payload.MobilePrepaidCommission,
      };
    case FETCH_PAN_COMMISION_RETAILER:
      return {
        ...state,
        PanCommission: action.payload.PanCommission,
      };
    case FETCH_RETAILERS_LIST:
      return {
        ...state,
        error: "",
      };
    case FETCH_RETAILERS_LIST_SUCCESS:
      return {
        ...state,
        retailers: action.payload.retailers,
        totalPages: action.payload.totalPages,
        currentpage: action.payload.currentpage,
        error: "",
      };
    case FETCH_RETAILERS_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case FETCH_RETAILER_UNDER_DISTRIBUTOR:
      return {
        ...state,
        error: "",
      };
    case FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS:
      return {
        ...state,
        retailersUnderDistributor: action.payload.retailersUnderDistributor,
        totalPages: action.payload.totalPages,
        currentpage: action.payload.currentpage,
        error: "",
      };
    case FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case FETCH_RETAILER_PAYMENT_LIST:
      return {
        ...state,
        error: "",
      };
    case FETCH_RETAILER_PAYMENT_LIST_SUCCESS:
      return {
        ...state,
        paymentList: action.payload.paymentList,
        totalPages: action.payload.totalPages,
        currentpage: action.payload.currentpage,
        error: "",
      };
    case FETCH_RETAILER_PAYMENT_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case FETCH_RETAILER_DISPUTE_LIST:
      return {
        ...state,
        error: "",
      };
    case FETCH_RETAILER_DISPUTE_LIST_SUCCESS:
      return {
        ...state,
        disputeList: action.payload.disputeList,
        totalPages: action.payload.totalPages,
        currentpage: action.payload.currentpage,
        error: "",
      };
    case FETCH_RETAILER_DISPUTE_LIST_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case FETCH_RETAILER_DISPUTE_DETAIL:
      return {
        ...state,
        error: "",
      };
    case FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS:
      return {
        ...state,
        disputeDetail: action.payload.disputeDetail,
        error: "",
      };
    case FETCH_RETAILER_DISPUTE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload.message,
      };
    case VIEW_RETAILERS_OF_DISTRIBUTORS:
      return {
        ...state,
        distributoreRetailers: action.distributoreRetailers,
      };
    case FETCH_RETAILERS_WALLET:
      return {
        ...state,
        walletBalance: action.payload.walletBalance,
        loading: false,
      };
    case FETCH_RETAILER_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.payload.profileImage,
      };
    case FETCH_RETAILER_NOTIFICATION:
      return {
        ...state,
        notificationValues: action.payload.notificationValues,
      };
    default:
      return state;
  }
};

export default retailer;
