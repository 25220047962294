import { FETCH_DESKTOPTOOLS, FETCH_DESKTOPTOOLS_SUCCESS } from "../actions";

const INIT_STATE = { desktoptools: [], totalPages: "", currentpage: 1 };

const desktoptool = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_DESKTOPTOOLS:
			return {
				...state,
			};
		case FETCH_DESKTOPTOOLS_SUCCESS:
			return {
				...state,
				desktoptools: action.payload.desktopTools,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
			};
		default:
			return state;
	}
};

export default desktoptool;
