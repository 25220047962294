import { FETCH_DRIVER, FETCH_DRIVER_SUCCESS } from "../actions";

const INIT_STATE = { drivers: [], totalPages: "", currentpage: 1 };

const driver = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_DRIVER:
			return {
				...state,
			};
		case FETCH_DRIVER_SUCCESS:
			return {
				...state,
				drivers: action.payload.drivers,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
			};
		default:
			return state;
	}
};

export default driver;
