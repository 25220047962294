import { VIEW_STATES, VIEW_STATES_SUCCESSFULL } from "../actions";

export const fetchStates = (country) => ({
	type: VIEW_STATES,
	payload: { country },
});

export const fetchStatesSuccessfull = (states) => ({
	type: VIEW_STATES_SUCCESSFULL,
	payload: { states },
});
