import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
	ADD_DOWNLOAD_LINK,
	FETCH_DOWNLOAD_LINK,
	MODIFY_DOWNLOAD_LINK,
} from "../actions";
import {
	addDownloadLinkFail,
	addDownloadLinkSuccess,
	fetchDownloadLinkSuccess,
	modifyDownloadLinkFail,
	modifyDownloadLinkSuccess,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchAddDownloadLink() {
	yield takeEvery(ADD_DOWNLOAD_LINK, addNewDownloadLinkFunc);
}

const newDownloadLink = async (name, type, url) => {
	try {
		const response = await axios.post(
			`${API_URL}downloads/add/download_link`,
			{
				name,
				type,
				url,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* addNewDownloadLinkFunc({ payload }) {
	console.log(payload);
	const { name, type, url } = payload.downloadData;
	try {
		const addDwonloadLinkResponse = yield call(
			newDownloadLink,
			name,
			type,
			url
		);
		if (addDwonloadLinkResponse.response) {
			if (addDwonloadLinkResponse.response.data.type === 3) {
				yield put(
					addDownloadLinkFail(
						addDwonloadLinkResponse.response.data.message
					)
				);
			} else if (addDwonloadLinkResponse.response.data.type === 1) {
				yield put(
					addDownloadLinkFail(
						addDwonloadLinkResponse.response.data.message
					)
				);
			} else {
				yield put(
					addDownloadLinkFail(
						addDwonloadLinkResponse.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				addDownloadLinkSuccess(
					addDwonloadLinkResponse.data.data.description
				)
			);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		}
	} catch (error) {
		yield put(addDownloadLinkFail(error));
	}
}

/******************WATCH LOAD DOWNLOAD LINKS FUNCTION******************/
export function* watchLoadDownloadLinks() {
	// while (true) {
	// 	yield take(VIEW_DOWNLOAD_LINK);
	// 	yield call(loadDownloadLinks);
	// }
	yield takeEvery(FETCH_DOWNLOAD_LINK, loadDownloadLinks);
}

const getDownloadLinks = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}downloads/list?page_no=${currentPage}&limit=${pageLimit}`,
			{
				AuthToken,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

export function* loadDownloadLinks({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const downloadLinks = yield call(
			getDownloadLinks,
			currentPage,
			pageLimit
		);
		if (downloadLinks) {
			yield put(
				fetchDownloadLinkSuccess(
					downloadLinks.data.data.items[0].downloadLinks,
					downloadLinks.data.data.items[0].numberOfPages,
					downloadLinks.data.data.items[0].currentPageNumber
				)
			);
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/****************************MODIFY DOWNLOAD LINK*******************/

export function* watchModifyDownloadLink() {
	yield takeEvery(MODIFY_DOWNLOAD_LINK, modifyDownloadLinkfunc);
}

const modifyDownloadLinkOperation = async (type, url, new_name, old_name) => {
	try {
		const response = await axios.put(
			`${API_URL}downloads/modify/download_link/${old_name}`,
			{ type, url, new_name, old_name }
		);

		return response;
	} catch (error) {
		return error;
	}
};

export function* modifyDownloadLinkfunc({ payload }) {
	console.clear();
	console.log(payload);
	const { type, url, new_name, old_name } = payload.modifyDownloadLinkData;
	try {
		let modifyDownloadLinkVar = yield call(
			modifyDownloadLinkOperation,
			type,
			url,
			new_name,
			old_name
		);

		if (modifyDownloadLinkVar.response) {
			if (modifyDownloadLinkVar.response.data.type === 0) {
				yield put(
					modifyDownloadLinkFail(
						modifyDownloadLinkVar.response.data.errors[0].message
					)
				);
			} else if (modifyDownloadLinkVar.response.data.type === 2) {
				yield put(
					modifyDownloadLinkFail(
						modifyDownloadLinkVar.response.data.message
					)
				);
			} else {
				yield put(
					modifyDownloadLinkFail(
						modifyDownloadLinkVar.response.data.message
					)
				);
			}
		} else {
			yield put(
				modifyDownloadLinkSuccess(
					modifyDownloadLinkVar.data.data.description
				)
			);
			setTimeout(function () {
				window.location.reload();
			}, 2000);
		}
	} catch (error) {
		yield put(modifyDownloadLinkFail(error));
	}
}

export default function* rootSaga() {
	yield all([
		fork(watchAddDownloadLink),
		fork(watchLoadDownloadLinks),
		fork(watchModifyDownloadLink),
	]);
}
