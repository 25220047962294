import {
	ADD_CITY,
	ADD_CITY_SUUCESSFULL,
	FETCH_CITY,
	FETCH_CITY_SUCCESSFULL,
	CITY_ERROR,
	MODIFY_CITY,
	MODIFY_CITY_SUCCESSFULL,
	MODIFY_CITY_FAIL,
	FETCH_CITY_FAIL,
} from "../actions";

export const addNewCity = (newCity, history) => ({
	type: ADD_CITY,
	payload: { newCity, history },
});

export const addNewCitySuucessfull = (message) => ({
	type: ADD_CITY_SUUCESSFULL,
	payload: { message },
});

export const addCityError = (message) => ({
	type: CITY_ERROR,
	payload: { message },
});

export const fetchCity = (state) => ({
	type: FETCH_CITY,
	payload: { state },
});

export const fetchCitySuccessfull = (cities) => ({
	type: FETCH_CITY_SUCCESSFULL,
	payload: { cities },
});

export const fetchCityFail = (message) => ({
	type: FETCH_CITY_FAIL,
	payload: { message },
});

export const modifyCity = (cityValues) => ({
	type: MODIFY_CITY,
	payload: { cityValues },
});

export const modifyCitySuccessfull = (message) => ({
	type: MODIFY_CITY_SUCCESSFULL,
	payload: { message },
});

export const modifyCityFail = (message) => ({
	type: MODIFY_CITY_FAIL,
	payload: { message },
});
