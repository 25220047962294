import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { FETCH_DESKTOPTOOLS } from "../actions";
import { fetchdesktopToolsSuccess } from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchLoadDesktoptools() {
	yield takeEvery(FETCH_DESKTOPTOOLS, loadDesktoptools);
}

const getDesktoptools = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}downloads/list?type=Desktop Tools&page_no=${currentPage}&limit=${pageLimit}`,
			{
				AuthToken,
			}
		);
		console.log(response);
		return response.data.data.items;
	} catch (error) {
		return error.response;
	}
};

export function* loadDesktoptools({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const desktoptools = yield call(
			getDesktoptools,
			currentPage,
			pageLimit
		);
		if (desktoptools) {
			yield put(
				fetchdesktopToolsSuccess(
					desktoptools[0].downloadLinks,
					desktoptools[0].numberOfPages,
					desktoptools[0].currentPageNumber
				)
			);
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		// return error;
		console.log(error);
	}
}
export default function* rootSaga() {
	yield all([fork(watchLoadDesktoptools)]);
}
