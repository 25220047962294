import {
	FETCH_AEPS_COMMISION_ADMIN,
	FETCH_DMT_COMMISION_ADMIN,
	FETCH_DTH_COMMISION_ADMIN,
	FETCH_ELECTRICITY_COMMISION_ADMIN,
	FETCH_MOBILE_POSTPAID_COMMISION_ADMIN,
	FETCH_MOBILE_PREPAID_COMMISION_ADMIN,
	FETCH_PAN_COMMISION_ADMIN,
	UPDATE_ADMIN_AEPS_COMMISSION,
	UPDATE_ADMIN_AEPS_COMMISSION_FAIL,
	UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS,
	UPDATE_ADMIN_DMT_COMMISSION,
	UPDATE_ADMIN_DMT_COMMISSION_FAIL,
	UPDATE_ADMIN_DMT_COMMISSION_SUCCESS,
	UPDATE_ADMIN_DTH_COMMISSION,
	UPDATE_ADMIN_DTH_COMMISSION_FAIL,
	UPDATE_ADMIN_DTH_COMMISSION_SUCCESS,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL,
	UPDATE_ADMIN_POSTPAID_COMMISSION,
	UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS,
	UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL,
	UPDATE_ADMIN_PREPAID_COMMISSION,
	UPDATE_ADMIN_PREPAID_COMMISSION_FAIL,
	UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS,
	UPDATE_ADMIN_PAN_COMMISSION,
	UPDATE_ADMIN_PAN_COMMISSION_FAIL,
	UPDATE_ADMIN_PAN_COMMISSION_SUCCESS,
	UPLOAD_PROFILE_PIC_EMPLOYEE,
	UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL,
	UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS,
	FETCH_PROFILE_PIC_EMPLOYEE,
} from "../actions";

export const fetchAEPSCommision = (AEPSCommisionData) => ({
	type: FETCH_AEPS_COMMISION_ADMIN,
	payload: { AEPSCommisionData },
});

export const fetchDMTCommision = (DMTCommision) => ({
	type: FETCH_DMT_COMMISION_ADMIN,
	payload: { DMTCommision },
});

export const fetchDTHCommision = (DTHCommision) => ({
	type: FETCH_DTH_COMMISION_ADMIN,
	payload: { DTHCommision },
});

export const fetchElectricityCommission = (ElectricityCommission) => ({
	type: FETCH_ELECTRICITY_COMMISION_ADMIN,
	payload: { ElectricityCommission },
});

export const fetchMobilePostpaidCommission = (MobilePostpaidCommission) => ({
	type: FETCH_MOBILE_POSTPAID_COMMISION_ADMIN,
	payload: { MobilePostpaidCommission },
});

export const fetchMobilePrepaidCommission = (MobilePrepaidCommission) => ({
	type: FETCH_MOBILE_PREPAID_COMMISION_ADMIN,
	payload: { MobilePrepaidCommission },
});

export const fetchPanCommission = (PanCommission) => ({
	type: FETCH_PAN_COMMISION_ADMIN,
	payload: { PanCommission },
});

export const updateAEPSCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_AEPS_COMMISSION,
	payload: { commissionData, history },
});

export const udpateAEPSCommissionFail = (message) => ({
	type: UPDATE_ADMIN_AEPS_COMMISSION_FAIL,
	payload: { message },
});

export const updateAEPSCommissionsuccess = (message) => ({
	type: UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS,
	payload: { message },
});

export const updateDMTCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_DMT_COMMISSION,
	payload: { commissionData, history },
});

export const updateDMTCommissionFail = (message) => ({
	type: UPDATE_ADMIN_DMT_COMMISSION_FAIL,
	payload: { message },
});

export const updateDMTCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_DMT_COMMISSION_SUCCESS,
	payload: { message },
});

export const updateDTHCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_DTH_COMMISSION,
	payload: { commissionData, history },
});

export const updateDTHCommissionFail = (message) => ({
	type: UPDATE_ADMIN_DTH_COMMISSION_FAIL,
	payload: { message },
});

export const updateDTHCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_DTH_COMMISSION_SUCCESS,
	payload: { message },
});
export const updateElectricityCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_ELECTRICITY_COMMISSION,
	payload: { commissionData, history },
});

export const updateElectricityCommissionFail = (message) => ({
	type: UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL,
	payload: { message },
});

export const updateElectricityCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS,
	payload: { message },
});

export const updatePostpaidCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_POSTPAID_COMMISSION,
	payload: { commissionData, history },
});

export const updatePostpaidCommissionFail = (message) => ({
	type: UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL,
	payload: { message },
});

export const updatePostpaidCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS,
	payload: { message },
});

export const updatePrepaidCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_PREPAID_COMMISSION,
	payload: { commissionData, history },
});

export const updatePrepaidCommissionFail = (message) => ({
	type: UPDATE_ADMIN_PREPAID_COMMISSION_FAIL,
	payload: { message },
});

export const updatePrepaidCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS,
	payload: { message },
});

export const updatePanCommission = (commissionData, history) => ({
	type: UPDATE_ADMIN_PAN_COMMISSION,
	payload: { commissionData, history },
});

export const updatePanCommissionFail = (message) => ({
	type: UPDATE_ADMIN_PAN_COMMISSION_FAIL,
	payload: { message },
});

export const updatePanCommissionSuccess = (message) => ({
	type: UPDATE_ADMIN_PAN_COMMISSION_SUCCESS,
	payload: { message },
});

export const fetchProfilePic = (profileImageData) => ({
	type: FETCH_PROFILE_PIC_EMPLOYEE,
	payload: { profileImageData },
});

export const uploadProfilePic = (profilePic) => ({
	type: UPLOAD_PROFILE_PIC_EMPLOYEE,
	payload: { profilePic },
});

export const uploadProfilePicFail = (message) => ({
	type: UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL,
	payload: { message },
});

export const uploadProfilePicSuccess = (message) => ({
	type: UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS,
	payload: { message },
});
