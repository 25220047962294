import {
	ADD_CITY,
	ADD_CITY_SUUCESSFULL,
	FETCH_CITY,
	FETCH_CITY_SUCCESSFULL,
	CITY_ERROR,
	MODIFY_CITY,
	MODIFY_CITY_SUCCESSFULL,
	MODIFY_CITY_FAIL,
	FETCH_CITY_FAIL,
} from "../actions";

const INIT_STATE = {
	error: "",
	cities: [],
	city_add_message: "",
	modify_city_message: "",
	modify_city_fail_response: "",
	fetch_city_fail_message: "",
	state: {},
	parentError: "",
	loading: false,
};

const city = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_CITY:
			return {
				...state,
				newCity: action.payload.newCity,
				loading: true,
				error: "",
			};
		case ADD_CITY_SUUCESSFULL:
			return {
				...state,
				city_add_message: action.payload.message,
				error: "",
				loading: false,
			};
		case CITY_ERROR:
			return {
				...state,
				error: action.payload.message,
				city_add_message: "",
				loading: false,
			};
		case FETCH_CITY:
			return {
				...state,
				state: action,
			};
		case FETCH_CITY_SUCCESSFULL:
			return {
				...state,
				cities: action.payload.cities,
				fetch_city_fail_message: "",
			};
		case FETCH_CITY_FAIL:
			console.log("fetchfail", action);
			return {
				...state,
				fetch_city_fail_message: action.payload.message,
			};

		case MODIFY_CITY:
			return {
				...state,
				cityValues: action.payload.cityValues,
				loading: true,
				error: "",
			};
		case MODIFY_CITY_SUCCESSFULL:
			// console.log("modifycitysuccess", action);
			return {
				...state,
				modify_city_message: action.payload.message,
				loading: false,
				error: "",
			};
		case MODIFY_CITY_FAIL:
			// console.log("modifycityfail", action);
			return {
				...state,
				error: action.payload.message,
				loading: false,
				modify_city_message: "",
			};
		default:
			return state;
	}
};

export default city;
