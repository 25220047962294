import { FETCH_DESKTOPTOOLS, FETCH_DESKTOPTOOLS_SUCCESS } from "../actions";

export const fetchDesktopTools = (currentPage, pageLimit) => ({
	type: FETCH_DESKTOPTOOLS,
	payload: { currentPage, pageLimit },
});

export const fetchdesktopToolsSuccess = (
	desktopTools,
	totalPages,
	currentpage
) => ({
	type: FETCH_DESKTOPTOOLS_SUCCESS,
	payload: { desktopTools, totalPages, currentpage },
});
