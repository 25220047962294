import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import axios from "axios";
import {
  FETCH_AEPS_COMMISION_RETAILER,
  FETCH_DMT_COMMISION_RETAILER,
  FETCH_DTH_COMMISION_RETAILER,
  FETCH_ELECTRICITY_COMMISION_RETAILER,
  FETCH_MOBILE_POSTPAID_COMMISION_RETAILER,
  FETCH_MOBILE_PREPAID_COMMISION_RETAILER,
  FETCH_PAN_COMMISION_RETAILER,
  FETCH_RETAILERS_LIST,
  FETCH_RETAILER_UNDER_DISTRIBUTOR,
  FETCH_RETAILER_PAYMENT_LIST,
  VIEW_RETAILERS_OF_DISTRIBUTORS,
  ADD_RETAILER,
  ADD_RETAILER_DISPUTE,
  ADD_RETAILER_DISPUTE_COMMENT,
  RETAILER_ADD_PAYMENT,
  FETCH_RETAILERS_WALLET,
  FETCH_RETAILER_PROFILE_IMAGE,
  FETCH_RETAILER_NOTIFICATION,
  FETCH_RETAILER_DISPUTE_LIST,
  FETCH_RETAILER_DISPUTE_DETAIL,
  // ADD_RETAILER_FAIL,
  // ADD_RETAILER_SUCCESS,
} from "../actions";
import {
  fetchAEPSCommision,
  fetchElectricityCommission,
  fetchDMTCommision,
  fetchDTHCommision,
  fetchMobilePostpaidCommission,
  fetchMobilePrepaidCommission,
  fetchPanCommission,
  addRetailerFail,
  addRetailerSuccess,
  addRetailerDisputeFail,
  addRetailerDisputeSuccess,
  addPaymentFail,
  addPaymentSuccess,
  addRetailerDisputeCommentFail,
  addRetailerDisputeCommentSuccess,
  fetchRetailersListSuccess,
  fetchPaymentListSuccess,
  fetchRetailerOfDistributorSuccess,
  fetchDisputeListSuccess,
  fetchDisputeDetailsSuccess,
  fetchRetailersOfDistributors,
  fetchRetailerWallet,
  fetchNotification,
  // fetchRetailerProfileImage,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

/*************ADD RETAILER**********/
/*************ADD DISTRIBUTOR********/
export function* watchAddRetailer() {
  yield takeEvery(ADD_RETAILER, addNewRetailerFunc);
}

const newRetailer = async (
  name,
  email,
  primary_mobile,
  secondary_mobile,
  password,
  gender,
  dob,
  address,
  town,
  tehsil,
  district,
  state,
  country,
  pincode,
  gstin,
  pan,
  aadhar,
  shop_name
) => {
  try {
    const response = await axios.post(
      `${API_URL}users/retailers/register`,
      // { AuthToken },
      {
        name,
        email,
        primary_mobile,
        secondary_mobile,
        password,
        gender,
        dob,
        address,
        town,
        tehsil,
        district,
        state,
        country,
        pincode,
        gstin,
        pan,
        aadhar,
        shop_name,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewRetailerFunc({ payload }) {
  const {
    name,
    email,
    primary_mobile,
    secondary_mobile,
    password,
    gender,
    dob,
    address,
    town,
    tehsil,
    district,
    state,
    country,
    pincode,
    gstin,
    pan,
    aadhar,
    shop_name,
  } = payload.retailerValues;
  try {
    const addRetailerResponse = yield call(
      newRetailer,
      name,
      email,
      primary_mobile,
      secondary_mobile,
      password,
      gender,
      dob,
      address,
      town,
      tehsil,
      district,
      state,
      country,
      pincode,
      gstin,
      pan,
      aadhar,
      shop_name
    );

    if (addRetailerResponse.response) {
      if (addRetailerResponse.response.data.type === 2) {
        yield put(addRetailerFail(addRetailerResponse.response.data.message));
      } else if (addRetailerResponse.response.data.type === 1) {
        yield put(addRetailerFail(addRetailerResponse.response.data.message));
      } else if (addRetailerResponse.response.data.type === 3) {
        yield put(addRetailerFail(addRetailerResponse.response.data.message));
      } else {
        yield put(
          addRetailerFail(addRetailerResponse.response.data.errors[0].message)
        );
      }
    } else {
      yield put(addRetailerSuccess(addRetailerResponse.data.data.description));
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    yield put(addRetailerFail(error));
  }
}

/********************ADD PAYMENT*****************/
export function* watchAddPayment() {
  yield takeEvery(RETAILER_ADD_PAYMENT, addNewPaymentFunc);
}

const newPayment = async (
  amount,
  payment_date,
  payment_method,
  bank_name,
  referenct_number,
  details
) => {
  try {
    const response = await axios.post(
      `${API_URL}payments/retailers/payment/request`,
      // { AuthToken },
      {
        amount,
        payment_date,
        payment_method,
        bank_name,
        referenct_number,
        details,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewPaymentFunc({ payload }) {
  const {
    amount,
    payment_date,
    payment_method,
    bank_name,
    referenct_number,
    details,
  } = payload.paymentValues;
  try {
    const addPaymentResponse = yield call(
      newPayment,
      amount,
      payment_date,
      payment_method,
      bank_name,
      referenct_number,
      details
    );

    if (addPaymentResponse.response) {
      if (addPaymentResponse.response.data.type === 2) {
        yield put(addPaymentFail(addPaymentResponse.response.data.message));
      } else if (addPaymentResponse.response.data.type === 1) {
        yield put(addPaymentFail(addPaymentResponse.response.data.message));
      } else if (addPaymentResponse.response.data.type === 3) {
        yield put(addPaymentFail(addPaymentResponse.response.data.message));
      } else {
        yield put(
          addPaymentFail(addPaymentResponse.response.data.errors[0].message)
        );
      }
    } else {
      yield put(addPaymentSuccess(addPaymentResponse.data.data.description));
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    yield put(addPaymentFail(error));
  }
}

/*****************ADD DISPUTE*******************/
export function* watchAddRetailerDispute() {
  yield takeEvery(ADD_RETAILER_DISPUTE, addNewDisputeFunc);
}

const newDispute = async (subject, order_id, order_type, description) => {
  try {
    const response = await axios.post(
      `${API_URL}disputes/retailers/dispute`,
      // { AuthToken },
      {
        subject,
        order_id,
        order_type,
        description,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewDisputeFunc({ payload }) {
  const {
    subject,
    order_id,
    order_type,
    description,
  } = payload.retailerDisputeValues;
  try {
    const addDisputeResponse = yield call(
      newDispute,
      subject,
      order_id,
      order_type,
      description
    );

    if (addDisputeResponse.response) {
      if (addDisputeResponse.response.data.type === 2) {
        yield put(
          addRetailerDisputeFail(addDisputeResponse.response.data.message)
        );
      } else if (addDisputeResponse.response.data.type === 1) {
        yield put(
          addRetailerDisputeFail(addDisputeResponse.response.data.message)
        );
      } else if (addDisputeResponse.response.data.type === 3) {
        yield put(
          addRetailerDisputeFail(addDisputeResponse.response.data.message)
        );
      } else {
        yield put(
          addRetailerDisputeFail(
            addDisputeResponse.response.data.errors[0].message
          )
        );
      }
    } else {
      yield put(
        addRetailerDisputeSuccess(addDisputeResponse.data.data.description)
      );
      // setTimeout(function () {
      // 	window.location.reload();
      // }, 1000);
    }
  } catch (error) {
    yield put(addRetailerDisputeFail(error));
  }
}

/****************ADD DISPUTE COMMENT**************/

export function* watchAddRetailerDisputeComment() {
  yield takeEvery(ADD_RETAILER_DISPUTE_COMMENT, addNewDisputeCommentFunc);
}

const newDisputeComment = async (dispute_id, description) => {
  try {
    const response = await axios.post(
      `${API_URL}retailers/dispute/comment/add/${dispute_id}`,
      // { AuthToken },
      {
        dispute_id,
        description,
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};

function* addNewDisputeCommentFunc({ payload }) {
  const { dispute_id, description } = payload.retailerCommentValues;
  try {
    const addDisputeCommentResponse = yield call(
      newDisputeComment,
      dispute_id,
      description
    );

    if (addDisputeCommentResponse.response) {
      if (addDisputeCommentResponse.response.data.type === 2) {
        yield put(
          addRetailerDisputeCommentFail(
            addDisputeCommentResponse.response.data.message
          )
        );
      } else if (addDisputeCommentResponse.response.data.type === 1) {
        yield put(
          addRetailerDisputeCommentFail(
            addDisputeCommentResponse.response.data.message
          )
        );
      } else if (addDisputeCommentResponse.response.data.type === 3) {
        yield put(
          addRetailerDisputeCommentFail(
            addDisputeCommentResponse.response.data.message
          )
        );
      } else {
        yield put(
          addRetailerDisputeCommentFail(
            addDisputeCommentResponse.response.data.errors[0].message
          )
        );
      }
    } else {
      yield put(
        addRetailerDisputeCommentSuccess(
          addDisputeCommentResponse.data.data.description
        )
      );
      setTimeout(function () {
        window.location.reload();
      }, 1000);
    }
  } catch (error) {
    yield put(addRetailerDisputeCommentFail(error));
  }
}

/***************LOAD WALLET BALANCE*************/
export function* watchLoadWalletBalance() {
  while (true) {
    yield take(FETCH_RETAILERS_WALLET);
    yield call(loadRetailerWalletBalance);
  }
}

const getRetailerWalletBalance = async () => {
  try {
    const response = await axios.get(`${API_URL}wallets/retailers/my/balance`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadRetailerWalletBalance() {
  try {
    const retailerWalletBalance = yield call(getRetailerWalletBalance);
    if (retailerWalletBalance) {
      yield put(fetchRetailerWallet(retailerWalletBalance));
    } else {
      console.log("somemthing is wrong", retailerWalletBalance.response);
    }
  } catch (error) {
    console.log(error);
  }
}

/**************LOAD NOTIFICATION******/
export function* watchLoadNotification() {
  while (true) {
    yield take(FETCH_RETAILER_NOTIFICATION);
    yield call(loadNotificationFunction);
  }
}

const getNotification = async () => {
  try {
    const response = await axios.get(`${API_URL}notifications/retailers/list`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadNotificationFunction() {
  try {
    const notificationValues = yield call(getNotification);
    console.log(notificationValues);
    // if (notificationValues) {
    // 	yield put(fetchNotification(notificationValues));
    // } else {
    // 	console.log("somemthing is wrong", notificationValues.response);
    // }
  } catch (error) {
    console.log(error);
  }
}
/*************LOAD PROFILE IMAGE******/
export function* watchLoadProfileImage() {
  while (true) {
    yield take(FETCH_RETAILER_PROFILE_IMAGE);
    yield call(loadRetailerProfileImage);
  }
}

const getRetailerProfileImage = async () => {
  try {
    const response = await axios.get(
      `${API_URL}users/retailers/my/profile/image`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadRetailerProfileImage() {
  try {
    const retailerProfileImage = yield call(getRetailerProfileImage);
    console.log(retailerProfileImage);
    // if (retailerDistributors) {
    // 	yield put(fetchRetailersOfDistributors(retailerDistributors));
    // } else {
    // 	console.log("somemthing is wrong", retailerDistributors.response);
    // }
  } catch (error) {
    console.log(error);
  }
}

/************WATCH RETAILER**********/
export function* watchRetailersDistributor() {
  while (true) {
    yield take(VIEW_RETAILERS_OF_DISTRIBUTORS);
    yield call(loadRetailersDistributor);
  }
}

const getRetailerDistributor = async () => {
  try {
    const response = await axios.get(
      `${API_URL}users/distributors/my/retailers/list`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadRetailersDistributor() {
  try {
    const retailerDistributors = yield call(getRetailerDistributor);
    console.log(retailerDistributors);
    if (retailerDistributors) {
      yield put(fetchRetailersOfDistributors(retailerDistributors));
    } else {
      console.log("somemthing is wrong", retailerDistributors.response);
    }
  } catch (error) {
    console.log(error);
  }
}

/********************LOAD ALL RETAILERS*****************/
export function* watchLoadRetailers() {
  yield takeEvery(FETCH_RETAILERS_LIST, loadRetailers);
}

const getRetailers = async (currentPage, pageLimit) => {
  try {
    const response = await axios.get(
      `${API_URL}users/all/user/list?limit=${pageLimit}&page_no=${currentPage}`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};
// const getRetailers = async () => {
//   try {
//     const response = await axios.get(
//       `${API_URL}users/all/user/list?page_no=1&limit=100`,
//       {
//         AuthToken,
//       }
//     );

//     return response.data.data.items;
//   } catch (error) {
//     return error;
//   }
// };

export function* loadRetailers({ payload }) {
  const { currentPage, pageLimit } = payload;
  try {
    const retailers = yield call(getRetailers, currentPage, pageLimit);
    // const retailers = yield call(getRetailers);
    console.log(retailers);
    if (retailers) {
      yield put(
        fetchRetailersListSuccess(
          retailers[0].employees,
          retailers[0].numberOfPages,
          retailers[0].currentPageNumber
        )
      );
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}

/****************LAOD RETAILER UNDER DITIRBUTOR**************/
export function* watchLoadRetailerUnderDistributor() {
  yield takeEvery(
    FETCH_RETAILER_UNDER_DISTRIBUTOR,
    loadRetailersUnderDistributor
  );
}

const getRetailersUnderDistributor = async (currentPage, pageLimit) => {
  try {
    const response = await axios.get(
      `${API_URL}users/distributors/my/retailers/list?limit=${pageLimit}&page_no=${currentPage}`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadRetailersUnderDistributor({ payload }) {
  const { currentPage, pageLimit } = payload;
  try {
    const retailersUnderDistributor = yield call(
      getRetailersUnderDistributor,
      currentPage,
      pageLimit
    );
    console.log(retailersUnderDistributor);
    if (retailersUnderDistributor) {
      yield put(
        fetchRetailerOfDistributorSuccess(
          retailersUnderDistributor[0].retailers,
          retailersUnderDistributor[0].numberOfPages,
          retailersUnderDistributor[0].currentPageNumber
        )
      );
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}
/***************LAOD PAYMENT LIST**********/
export function* watchLoadPaymentList() {
  yield takeEvery(FETCH_RETAILER_PAYMENT_LIST, loadPaymentList);
}

const getPaymentList = async (currentPage, pageLimit) => {
  try {
    const response = await axios.get(
      `${API_URL}payments/retailers/my/payments/list?page_no=${currentPage}&limit=${pageLimit}`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadPaymentList({ payload }) {
  const { currentPage, pageLimit } = payload;
  try {
    const paymentLists = yield call(getPaymentList, currentPage, pageLimit);
    console.log(paymentLists);
    if (paymentLists) {
      yield put(
        fetchPaymentListSuccess(
          paymentLists[0].payments,
          paymentLists[0].numberOfPages,
          paymentLists[0].currentPageNumber
        )
      );
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}

/**********************LOAD DISPUTE LIST*************/
export function* watchLoadRetailerDisputeList() {
  yield takeEvery(FETCH_RETAILER_DISPUTE_LIST, loadRetailerDisputeList);
}

const getretailerDisputeList = async (currentPage, pageLimit) => {
  try {
    const response = await axios.get(
      `${API_URL}disputes/retailers/my/disputes/list?page_no=${currentPage}&limit=${pageLimit}&status`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadRetailerDisputeList({ payload }) {
  const { currentPage, pageLimit } = payload;
  try {
    const disputeList = yield call(
      getretailerDisputeList,
      currentPage,
      pageLimit
    );
    console.log(disputeList);
    if (disputeList) {
      // if (employeeList.status === 200) {
      yield put(
        fetchDisputeListSuccess(
          disputeList[0].disputes,
          disputeList[0].numberOfPages,
          disputeList[0].currentPageNumber
        )
      );
      // }
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
  }
}

/*************LOAD DISPUTE DETAIL**********/
export function* watchLoadRetailerDisputeDetail() {
  yield takeEvery(FETCH_RETAILER_DISPUTE_DETAIL, loadRetailerDisputeDetail);
}

const getretailerDisputeDetail = async (disputeId) => {
  try {
    const response = await axios.get(
      `${API_URL}disputes/retailers/dispute/details/${disputeId}`,
      {
        AuthToken,
      }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadRetailerDisputeDetail({ payload }) {
  const { disputeId } = payload;
  try {
    const disputeDetail = yield call(getretailerDisputeDetail, disputeId);
    console.log(disputeDetail);
    // if (disputeList) {
    // 	// if (employeeList.status === 200) {
    // 	yield put(
    // 		fetchDisputeListSuccess(
    // 			disputeList[0].disputes,
    // 			disputeList[0].numberOfPages,
    // 			disputeList[0].currentPageNumber
    // 		)
    // 	);
    // 	// }
    // } else {
    // 	console.log("something is worng");
    // }
  } catch (error) {
    return error;
  }
}

/*************LOAD AEPS COMMISSION*******/
export function* watchLoadAEPSCommision() {
  while (true) {
    yield take(FETCH_AEPS_COMMISION_RETAILER);
    yield call(loadAEPSCOmmision);
  }
}

const getAEPSCOmmision = async () => {
  try {
    const response = await axios.get(
      `${API_URL}commissions/retailers/my/aeps`,
      { AuthToken }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadAEPSCOmmision() {
  try {
    const AEPSCOmmision = yield call(getAEPSCOmmision);
    if (AEPSCOmmision) {
      yield put(fetchAEPSCommision(AEPSCOmmision));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}

/**************LOAD DMT COMMISSIONS***/
export function* watchLoadDMTCommision() {
  while (true) {
    yield take(FETCH_DMT_COMMISION_RETAILER);
    yield call(loadDMTCommission);
  }
}

const getDMTCommission = async () => {
  try {
    const response = await axios.get(`${API_URL}commissions/retailers/my/dmt`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadDMTCommission() {
  try {
    const DMTCommission = yield call(getDMTCommission);
    if (DMTCommission) {
      yield put(fetchDMTCommision(DMTCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}
/**************LAOD DTH COMMISSION*****/
export function* watchLoadDTHCommision() {
  while (true) {
    yield take(FETCH_DTH_COMMISION_RETAILER);
    yield call(loadDTHCommission);
  }
}

const getDTHCommission = async () => {
  try {
    const response = await axios.get(`${API_URL}commissions/retailers/my/dth`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadDTHCommission() {
  try {
    const DTHCommission = yield call(getDTHCommission);
    if (DTHCommission) {
      yield put(fetchDTHCommision(DTHCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}

/****************LOAD ELECTRICITY COMMISION***********/
export function* watchLoadElectricityCommision() {
  while (true) {
    yield take(FETCH_ELECTRICITY_COMMISION_RETAILER);
    yield call(loadElectrictyCommission);
  }
}

const getElectricityCommission = async () => {
  try {
    const response = await axios.get(
      `${API_URL}commissions/retailers/my/electricity`,
      { AuthToken }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadElectrictyCommission() {
  try {
    const ElectricityCommission = yield call(getElectricityCommission);
    if (ElectricityCommission) {
      yield put(fetchElectricityCommission(ElectricityCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}
/*************LOAD MOBILE POSTPAID COMMISSION*********/
export function* watchLoadMobilePostpaidCommision() {
  while (true) {
    yield take(FETCH_MOBILE_POSTPAID_COMMISION_RETAILER);
    yield call(loadMobilePostpaidCommission);
  }
}

const getMobilePostpaidCommission = async () => {
  try {
    const response = await axios.get(
      `${API_URL}commissions/retailers/my/mobile_postpaid`,
      { AuthToken }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadMobilePostpaidCommission() {
  try {
    const MobilePostpaidCommission = yield call(getMobilePostpaidCommission);
    if (MobilePostpaidCommission) {
      yield put(fetchMobilePostpaidCommission(MobilePostpaidCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}
/****************LOAD MOBILE PREPAID COMMISSION**********/
export function* watchLoadMobilePrepaidCommision() {
  while (true) {
    yield take(FETCH_MOBILE_PREPAID_COMMISION_RETAILER);
    yield call(loadMobilePrepaidCommission);
  }
}

const getMobilePrepaidCommission = async () => {
  try {
    const response = await axios.get(
      `${API_URL}commissions/retailers/my/mobile_prepaid`,
      { AuthToken }
    );

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadMobilePrepaidCommission() {
  try {
    const MobilePrepaidCommission = yield call(getMobilePrepaidCommission);
    if (MobilePrepaidCommission) {
      yield put(fetchMobilePrepaidCommission(MobilePrepaidCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}
/*************LOAD PAN COMMISSION*****/
export function* watchLoadPanCommission() {
  while (true) {
    yield take(FETCH_PAN_COMMISION_RETAILER);
    yield call(loadPanCommission);
  }
}

const getPanCommission = async () => {
  try {
    const response = await axios.get(`${API_URL}commissions/retailers/my/pan`, {
      AuthToken,
    });

    return response.data.data.items;
  } catch (error) {
    return error;
  }
};

export function* loadPanCommission() {
  try {
    const PanCommission = yield call(getPanCommission);
    console.log(PanCommission);
    if (PanCommission) {
      yield put(fetchPanCommission(PanCommission));
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    return error;
    // console.log(error);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoadRetailers),
    fork(watchRetailersDistributor),
    fork(watchAddRetailer),
    fork(watchAddPayment),
    fork(watchLoadAEPSCommision),
    fork(watchLoadDMTCommision),
    fork(watchLoadDTHCommision),
    fork(watchLoadElectricityCommision),
    fork(watchLoadMobilePostpaidCommision),
    fork(watchLoadMobilePrepaidCommision),
    fork(watchLoadPanCommission),
    fork(watchLoadWalletBalance),
    fork(watchLoadProfileImage),
    fork(watchLoadPaymentList),
    fork(watchLoadRetailerDisputeList),
    fork(watchLoadRetailerDisputeDetail),
    fork(watchAddRetailerDispute),
    fork(watchAddRetailerDisputeComment),
    fork(watchLoadNotification),
    fork(watchLoadRetailerUnderDistributor),
  ]);
}
