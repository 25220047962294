import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { FETCH_REPORT_EMPLOYEE } from "../actions";
import { fetchReportListsSuccess } from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchLoadDrivers() {
  yield takeEvery(FETCH_REPORT_EMPLOYEE, loadDrivers);
}

const getDrivers = async (currentPage, pageLimit) => {
  try {
    const response = await axios.get(
      `${API_URL}traction/list?page_no=${currentPage}&limit=${pageLimit}`,
      {
        AuthToken,
      }
    );
    return response.data.data.items;
  } catch (error) {
    return error.response;
  }
};

export function* loadDrivers({ payload }) {
  const { currentPage, pageLimit } = payload;
  try {
    const drivers = yield call(getDrivers, currentPage, pageLimit);
    console.log("driver", drivers);
    if (drivers) {
      yield put(
        fetchReportListsSuccess(
          drivers[0].tractions,
          drivers[0].numberOfPages,
          drivers[0].currentPageNumber
        )
      );
    } else {
      console.log("something is worng");
    }
  } catch (error) {
    // return error;
    console.log(error);
  }
}
export default function* rootSaga() {
  yield all([fork(watchLoadDrivers)]);
}
