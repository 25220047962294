import { all, call, fork, put, take } from "redux-saga/effects";
import axios from "axios";
import { FETCH_COUNTRY } from "../actions";
import { fetchCountry } from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchLoadCountries() {
	while (true) {
		yield take(FETCH_COUNTRY);
		yield call(loadCountries);
	}
}

const getCountries = async () => {
	try {
		const response = await axios.get(`${API_URL}locations/country/list`, {
			AuthToken,
		});

		return response.data.data.items;
	} catch (error) {
		return error.response;
	}
};

export function* loadCountries() {
	try {
		const countries = yield call(getCountries);
		if (countries) {
			yield put(fetchCountry(countries));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		// return error;
		console.log(error);
	}
}
export default function* rootSaga() {
	yield all([fork(watchLoadCountries)]);
}
