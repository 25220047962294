import {
	FETCH_AEPS_COMMISION_ADMIN,
	FETCH_DMT_COMMISION_ADMIN,
	FETCH_DTH_COMMISION_ADMIN,
	FETCH_ELECTRICITY_COMMISION_ADMIN,
	FETCH_MOBILE_POSTPAID_COMMISION_ADMIN,
	FETCH_MOBILE_PREPAID_COMMISION_ADMIN,
	FETCH_PAN_COMMISION_ADMIN,
	UPDATE_ADMIN_AEPS_COMMISSION,
	UPDATE_ADMIN_AEPS_COMMISSION_FAIL,
	UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS,
	UPDATE_ADMIN_DMT_COMMISSION,
	UPDATE_ADMIN_DMT_COMMISSION_FAIL,
	UPDATE_ADMIN_DMT_COMMISSION_SUCCESS,
	UPDATE_ADMIN_DTH_COMMISSION,
	UPDATE_ADMIN_DTH_COMMISSION_FAIL,
	UPDATE_ADMIN_DTH_COMMISSION_SUCCESS,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL,
	UPDATE_ADMIN_POSTPAID_COMMISSION,
	UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS,
	UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL,
	UPDATE_ADMIN_PREPAID_COMMISSION,
	UPDATE_ADMIN_PREPAID_COMMISSION_FAIL,
	UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS,
	UPDATE_ADMIN_PAN_COMMISSION,
	UPDATE_ADMIN_PAN_COMMISSION_FAIL,
	UPDATE_ADMIN_PAN_COMMISSION_SUCCESS,
	UPLOAD_PROFILE_PIC_EMPLOYEE,
	UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL,
	UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS,
	FETCH_PROFILE_PIC_EMPLOYEE,
} from "../actions";

const INIT_STATE = {
	AEPSCommisionData: [],
	DMTCommision: [],
	DTHCommision: [],
	ElectricityCommission: [],
	MobilePostpaidCommission: [],
	MobilePrepaidCommission: [],
	PanCommission: [],
	loading: false,
	error: "",
	update_commission_success_message: "",
	profile_pic_upload_success_msg: "",
	profileImageData: [],
};

const admin = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_AEPS_COMMISION_ADMIN:
			return {
				...state,
				AEPSCommisionData: action.payload.AEPSCommisionData,
			};
		case FETCH_DMT_COMMISION_ADMIN:
			return {
				...state,
				DMTCommision: action.payload.DMTCommision,
			};
		case FETCH_DTH_COMMISION_ADMIN:
			return {
				...state,
				DTHCommision: action.payload.DTHCommision,
			};
		case FETCH_ELECTRICITY_COMMISION_ADMIN:
			return {
				...state,
				ElectricityCommission: action.payload.ElectricityCommission,
			};
		case FETCH_MOBILE_POSTPAID_COMMISION_ADMIN:
			return {
				...state,
				MobilePostpaidCommission:
					action.payload.MobilePostpaidCommission,
			};
		case FETCH_MOBILE_PREPAID_COMMISION_ADMIN:
			return {
				...state,
				MobilePrepaidCommission: action.payload.MobilePrepaidCommission,
			};
		case FETCH_PAN_COMMISION_ADMIN:
			return {
				...state,
				PanCommission: action.payload.PanCommission,
			};
		case UPDATE_ADMIN_AEPS_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_AEPS_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_AEPS_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_DMT_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_DMT_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_DMT_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_DTH_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_DTH_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_DTH_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_ELECTRICITY_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_ELECTRICITY_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_ELECTRICITY_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_POSTPAID_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_POSTPAID_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_POSTPAID_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_PREPAID_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_PREPAID_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_PREPAID_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_PAN_COMMISSION:
			return {
				...state,
				error: "",
				loading: true,
				update_commission_success_message: "",
			};
		case UPDATE_ADMIN_PAN_COMMISSION_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				update_commission_success_message: action.payload.message,
			};
		case UPDATE_ADMIN_PAN_COMMISSION_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
				update_commission_success_message: "",
			};
		case FETCH_PROFILE_PIC_EMPLOYEE:
			return {
				...state,
				profileImageData: action.payload.profileImageData,
			};
		case UPLOAD_PROFILE_PIC_EMPLOYEE:
			return {
				...state,
				loading: true,
				error: "",
				profile_pic_upload_success_msg: "",
			};
		case UPLOAD_PROFILE_PIC_EMPLOYEE_FAIL:
			return {
				...state,
				loading: false,
				profile_pic_upload_success_msg: "",
				error: action.payload.message,
			};
		case UPLOAD_PROFILE_PIC_EMPLOYEE_SUCCESS:
			return {
				...state,
				loading: false,
				error: "",
				profile_pic_upload_success_msg: action.payload.message,
			};
		default:
			return state;
	}
};

export default admin;
