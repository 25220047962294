import { FETCH_REPORT_EMPLOYEE, FETCH_REPORT_EMPLOYEE_SUCCESS } from "../actions";

export const fetchReport = (currentPage, pageLimit) => ({
	type: FETCH_REPORT_EMPLOYEE,
	payload: { currentPage, pageLimit },
});

export const fetchReportListsSuccess = (report, totalPages, currentpage) => ({
	type: FETCH_REPORT_EMPLOYEE_SUCCESS,
	payload: { report, totalPages, currentpage },
});
