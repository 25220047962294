import {
	ADD_DOWNLOAD_LINK,
	ADD_DOWNLOAD_LINK_FAIL,
	ADD_DOWNLOAD_LINK_SUCCESS,
	FETCH_DOWNLOAD_LINK,
	FETCH_DOWNLOAD_LINK_FAIL,
	FETCH_DOWNLOAD_LINK_SUCCESS,
	// FETCH_FILTER_DOWNLOAD_LINK,
	// FETCH_FILTER_DOWNLOAD_LINK_FAIL,
	// FETCH_FILTER_DOWNLOAD_LINK_SUCCESS,
	MODIFY_DOWNLOAD_LINK,
	MODIFY_DOWNLOAD_LINK_FAIL,
	MODIFY_DOWNLOAD_LINK_SUCCESS,
} from "../actions";

const INIT_STATE = {
	loading: false,
	add_download_link_success_message: "",
	modify_download_link_success_message: "",
	error: "",
	downloadLinks: [],
	filterdownloadLinks: [],
	totalPages: "",
	currentpage: 1,
};

const download = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_DOWNLOAD_LINK:
			return {
				...state,
				error: "",

				// currentPage: action.payload.currentPage,
			};
		case FETCH_DOWNLOAD_LINK_SUCCESS:
			return {
				...state,
				error: "",
				downloadLinks: action.payload.downloadLinks,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
			};
		case FETCH_DOWNLOAD_LINK_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case ADD_DOWNLOAD_LINK:
			return {
				...state,
				error: "",
				loading: true,
			};
		case ADD_DOWNLOAD_LINK_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.message,
			};
		case ADD_DOWNLOAD_LINK_SUCCESS:
			return {
				...state,
				loading: false,
				error: "",
				add_download_link_success_message: action.payload.message,
			};
		case MODIFY_DOWNLOAD_LINK:
			return {
				...state,
				error: "",
				loading: true,
			};
		case MODIFY_DOWNLOAD_LINK_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.message,
			};
		case MODIFY_DOWNLOAD_LINK_SUCCESS:
			return {
				...state,
				loading: false,
				error: "",
				modify_download_link_success_message: action.payload.message,
			};
		default:
			return state;
	}
};

export default download;
