import {
	FETCH_AEPS_COMMISION,
	FETCH_DTH_COMMISION,
	FETCH_DMT_COMMISION,
	FETCH_FILTER_DISTRIBTUOR,
	FETCH_ELECTRICITY_COMMISION,
	FETCH_MOBILE_POSTPAID_COMMISION,
	FETCH_MOBILE_PREPAID_COMMISION,
	FETCH_PAN_COMMISION,
	FETCH_FILTER_DISTRIBTUOR_FAIL,
	FETCH_FILTER_DISTRIBTUOR_SUCCESS,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
	ADD_DISTRIBUTERS,
	ADD_DISTRIBUTERS_FAIL,
	ADD_DISTRIBUTERS_SUCCESS,
	FETCH_DISTRIBUTORS_LIST,
	FETCH_DISTRIBUTORS_LIST_FAIL,
	FETCH_DISTRIBUTORS_LIST_SUCCESS,
	VIEW_DISTRIBUTOR_DETAIL,
	MODIFY_DISTRIBUTOR,
	MODIFY_DISTRIBUTOR_FAIL,
	MODIFY_DISTRIBUTOR_SUCCESS,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS,
} from "../actions";

const INIT_STATE = {
	distributors: [],
	distributorDetail: [],
	filterDistributor: [],
	filterDistributorRetailerData: [],
	AEPSCommisionData: [],
	DMTCommision: [],
	DTHCommision: [],
	ElectricityCommission: [],
	MobilePostpaidCommission: [],
	MobilePrepaidCommission: [],
	PanCommission: [],
	loading: false,
	modify_success_message: "",
	add_distributor_success_message: "",
	error: "",
	totalPages: "",
	currentpage: 1,
	profile_pic_upload_success_msg: "",
	profileImageData: [],
};

const distributor = (state = INIT_STATE, action) => {
	switch (action.type) {
		case ADD_DISTRIBUTERS:
			return {
				...state,
				error: "",
				distributorValues: action.payload.distributorValues,
				loading: true,
			};
		case ADD_DISTRIBUTERS_FAIL:
			return {
				...state,
				error: action.payload.message,
				add_distributor_success_message: "",
				loading: false,
			};
		case ADD_DISTRIBUTERS_SUCCESS:
			return {
				...state,
				loading: false,
				error: "",
				add_distributor_success_message: action.payload.message,
			};
		case FETCH_FILTER_DISTRIBTUOR:
			return {
				...state,
				distributorId: action.payload.distributorId,
			};
		case FETCH_FILTER_DISTRIBTUOR_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case FETCH_FILTER_DISTRIBTUOR_SUCCESS:
			return {
				...state,
				error: "",
				filterDistributor: action.payload.filterDistributor,
			};
		case FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR:
			return {
				...state,
				distributorId: action.payload.distributorId,
			};
		case FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS:
			return {
				...state,
				error: "",
				filterDistributorRetailerData: action.payload.filterRetailer,
			};
		case FETCH_AEPS_COMMISION:
			return {
				...state,
				AEPSCommisionData: action.payload.AEPSCommisionData,
			};
		case FETCH_DMT_COMMISION:
			return {
				...state,
				DMTCommision: action.payload.DMTCommision,
			};
		case FETCH_DTH_COMMISION:
			return {
				...state,
				DTHCommision: action.payload.DTHCommision,
			};
		case FETCH_ELECTRICITY_COMMISION:
			return {
				...state,
				ElectricityCommission: action.payload.ElectricityCommission,
			};
		case FETCH_MOBILE_POSTPAID_COMMISION:
			return {
				...state,
				MobilePostpaidCommission:
					action.payload.MobilePostpaidCommission,
			};
		case FETCH_MOBILE_PREPAID_COMMISION:
			return {
				...state,
				MobilePrepaidCommission: action.payload.MobilePrepaidCommission,
			};
		case FETCH_PAN_COMMISION:
			return {
				...state,
				PanCommission: action.payload.PanCommission,
			};
		case FETCH_DISTRIBUTORS_LIST:
			return {
				...state,
				error: "",
			};
		case FETCH_DISTRIBUTORS_LIST_SUCCESS:
			return {
				...state,
				distributors: action.payload.distributors,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
				error: "",
			};
		case FETCH_DISTRIBUTORS_LIST_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case VIEW_DISTRIBUTOR_DETAIL:
			return {
				...state,
				distributorDetail: action.payload.detail,
				error: "",
			};
		case MODIFY_DISTRIBUTOR:
			return {
				...state,
				error: "",
				data: action.payload.data,
				loading: true,
			};
		case MODIFY_DISTRIBUTOR_SUCCESS:
			return {
				...state,
				modify_success_message: action.payload.message,
				loading: false,
				error: "",
			};
		case MODIFY_DISTRIBUTOR_FAIL:
			return {
				...state,
				modify_success_message: "",
				error: action.payload.message,
				loading: false,
			};
		case UPLOAD_PROFILE_PIC_DISTRIBUTOR:
			return {
				...state,
				loading: true,
				error: "",
				profile_pic_upload_success_msg: "",
			};
		case UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL:
			return {
				...state,
				loading: false,
				profile_pic_upload_success_msg: "",
				error: action.payload.message,
			};
		case UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS:
			return {
				...state,
				loading: false,
				error: "",
				profile_pic_upload_success_msg: action.payload.message,
			};
		default:
			return state;
	}
};

export default distributor;
