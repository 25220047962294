import { all, call, fork, put, takeEvery, take } from "redux-saga/effects";
import axios from "axios";
import {
	ADD_EMPLOYEE,
	ADD_EMPLOYEE_DISPUTE_COMMENT,
	MODIFY_EMPLOYEE,
	VIEW_EMPLOYEE_DETAIL,
	FETCH_EMPLOYEE_LIST,
	FETCH_EMPLOYEE_PAYMENT_LIST,
	FETCH_EMPLOYEE_DISPUTE_LIST,
	FETCH_EMPLOYEE_DISPUTE_DETAIL
} from "../actions";
import {
	fetchEmployeeListSuccess,
	fetchPaymentListSuccess,
	fetchDisputeListSuccess,
	fetchDisputeDetailsFail,
fetchDisputeDetailsSuccess,
	addEmployeeSuccess,
	addEmployeeFail,
	addEmployeeDisputeCommentFail,
addEmployeeDisputeCommentSuccess,
	modifyEmployeeSuccess,
	modifyEmployeeFail,
	fetchEmployeeDetail,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

/***********************ADD EMPLOYEE*******************/
export function* watchAddEmployee() {
	yield takeEvery(ADD_EMPLOYEE, addNewEmployeeFunc);
}

const newEmployee = async (
	name,
	email,
	mobile,
	password,
	address,
	city,
	bgl_head,
      operator_id,
	  branch_code,
	  bank_name,
) => {
	try {
		const response = await axios.post(
			`${API_URL}users/user/register`,

			{
				name,
				email,
				mobile,
				password,
				address,
				city,
				bgl_head,
      			operator_id,
				  branch_code,
				  bank_name,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* addNewEmployeeFunc({ payload }) {
	const {
		name,
		email,
		mobile,
		password,
		address,
		city,
		bgl_head,
      operator_id,
	  branch_code,
	  bank_name,
	} = payload.employeeValue;
	try {
		const addEmployeeResponse = yield call(
			newEmployee,
			name,
			email,
			mobile,
			password,
			address,
			city,
			bgl_head,
      operator_id,
	  branch_code,
	  bank_name,
		);

		if (addEmployeeResponse.response) {
			if (addEmployeeResponse.response.data.type === 2) {
				yield put(
					addEmployeeFail(addEmployeeResponse.response.data.message)
				);
			} else if (addEmployeeResponse.response.data.type === 1) {
				yield put(
					addEmployeeFail(addEmployeeResponse.response.data.message)
				);
			} else {
				yield put(
					addEmployeeFail(
						addEmployeeResponse.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				addEmployeeSuccess(addEmployeeResponse.data.data.description)
			);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		}
	} catch (error) {
		yield put(addEmployeeFail(error));
	}
}

/***********************ADD DISPUTE COMMENT*****************/
export function* watchAddEmployeeDisputeComment() {
	yield takeEvery(ADD_EMPLOYEE_DISPUTE_COMMENT, addNewDisputeCommentFunc);
}

const newDisputeComment = async (dispute_id,
		description) => {
	try {
		const response = await axios.post(
			`${API_URL}employees/dispute/comment/add/${dispute_id}`,
			// { AuthToken },
			{
				dispute_id,
		description			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* addNewDisputeCommentFunc({ payload }) {
	console.log(payload)
	const {
		dispute_id,
		description
	} = payload.employeeCommentValues;
	try {
		const addDisputeCommentResponse = yield call(
			newDisputeComment,
			dispute_id,
		description
		);

		if (addDisputeCommentResponse.response) {
			if (addDisputeCommentResponse.response.data.type === 2) {
				yield put(
					addEmployeeDisputeCommentFail(
						addDisputeCommentResponse.response.data.message
					)
				);
			} else if (addDisputeCommentResponse.response.data.type === 1) {
				yield put(
					addEmployeeDisputeCommentFail(
						addDisputeCommentResponse.response.data.message
					)
				);
			} else if (addDisputeCommentResponse.response.data.type === 3) {
				yield put(
					addEmployeeDisputeCommentFail(
						addDisputeCommentResponse.response.data.message
					)
				);
			} else {
				yield put(
					addEmployeeDisputeCommentFail(
						addDisputeCommentResponse.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				addEmployeeDisputeCommentSuccess(
					addDisputeCommentResponse.data.data.description
				)
			);
			setTimeout(function () {
				window.location.reload();
			}, 1000);
		}
	} catch (error) {
		yield put(addEmployeeDisputeCommentFail(error));
	}
}
/**********************EMPLOYEE LIST******************/
export function* watchLoadEmployeeList() {
	yield takeEvery(FETCH_EMPLOYEE_LIST, loadEmployeeList);
}

const getEmployeeList = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}users/all/employees/list?limit=${pageLimit}&page_no=${currentPage}`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadEmployeeList({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const employeeList = yield call(
			getEmployeeList,
			currentPage,
			pageLimit
		);
		if (employeeList) {
			// if (employeeList.status === 200) {
			yield put(
				fetchEmployeeListSuccess(
					employeeList[0].employees,
					employeeList[0].numberOfPages,
					employeeList[0].currentPageNumber
				)
			);
			// }
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
	}
}

/**********************EMPLOYEE PAYMENT LIST************/
export function* watchLoadEmployeePaymentList() {
	yield takeEvery(FETCH_EMPLOYEE_PAYMENT_LIST, loadEmployeePaymentList);
}

const getEmployeePaymentList = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}payments/employee/retailers/payments/list?page_no=${currentPage}&limit=${pageLimit}`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadEmployeePaymentList({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const paymentList = yield call(
			getEmployeePaymentList,
			currentPage,
			pageLimit
		);
		console.log(paymentList);
		if (paymentList) {
			// if (employeeList.status === 200) {
			yield put(
				fetchPaymentListSuccess(
					paymentList[0].payments,
					paymentList[0].numberOfPages,
					paymentList[0].currentPageNumber
				)
			);
			// }
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
	}
}

/**********************EMPLOYEE DISPUTE LIST*************/
export function* watchLoadEmployeeDisputeList() {
	yield takeEvery(FETCH_EMPLOYEE_DISPUTE_LIST, loadEmployeeDisputeList);
}

const getEmployeeDisputeList = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}disputes/employees/disputes/list?page_no=${currentPage}&limit=${pageLimit}&status`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadEmployeeDisputeList({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const disputeList = yield call(
			getEmployeeDisputeList,
			currentPage,
			pageLimit
		);
		console.log(disputeList);
		if (disputeList) {
			// if (employeeList.status === 200) {
			yield put(
				fetchDisputeListSuccess(
					disputeList[0].disputes,
					disputeList[0].numberOfPages,
					disputeList[0].currentPageNumber
				)
			);
			// }
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
	}
}
/*************LOAD DISPUTE DETAIL**********/
export function* watchLoadEmployeeDisputeDetail() {
	yield takeEvery(FETCH_EMPLOYEE_DISPUTE_DETAIL, loadEmployeeDisputeDetail);
}

const getemployeeDisputeDetail = async (disputeId) => {
	try {
		const response = await axios.get(
			`${API_URL}disputes/employees/dispute/details/${disputeId}`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadEmployeeDisputeDetail({ payload }) {
	const { disputeId } = payload;
	try {
		const disputeDetail = yield call(getemployeeDisputeDetail, disputeId);
		console.log(disputeDetail);
		// if (disputeList) {
		// 	// if (employeeList.status === 200) {
		// 	yield put(
		// 		fetchDisputeListSuccess(
		// 			disputeList[0].disputes,
		// 			disputeList[0].numberOfPages,
		// 			disputeList[0].currentPageNumber
		// 		)
		// 	);
		// 	// }
		// } else {
		// 	console.log("something is worng");
		// }
	} catch (error) {
		return error;
	}
}

/**********************EMPLOYEE DETAIL****************/
export function* watchLoadEmployeeDetail() {
	while (true) {
		yield take(VIEW_EMPLOYEE_DETAIL);
		yield call(loadEmployeeDetail);
	}
}

const getEmployeeDetail = async () => {
	try {
		const response = await axios.get(
			`${API_URL}users/employees/my/profile`,
			{ AuthToken }
		);

		return response;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export function* loadEmployeeDetail() {
	try {
		const employeeDetail = yield call(getEmployeeDetail);
		console.log(employeeDetail);
		if (employeeDetail) {
			if (employeeDetail.status === 200) {
				yield put(fetchEmployeeDetail(employeeDetail.data.data.items));
			}
		}
	} catch (error) {
		return error;
	}
}

/************EMPLOYEE MODIFY*************/

export function* watchModifyEmployee() {
	yield takeEvery(MODIFY_EMPLOYEE, modifyEmployeeFunc);
}

const modifyEmployeeOperation = async (
	name,
	gender,
	dob,
	address,
	city,
	state,
	country,
	pincode
) => {
	try {
		const response = await axios.put(
			`${API_URL}users/employees/my/profile`,
			{
				name,
				gender,
				dob,
				address,
				city,
				state,
				country,
				pincode,
			}
			// {
			// 	params: {
			// 		old_name,
			// 	},
			// 	new_name,
			// 	state,
			// }
		);

		return response;
	} catch (error) {
		return error;
	}
};

export function* modifyEmployeeFunc({ payload }) {
	const {
		name,
		gender,
		dob,
		address,
		city,
		state,
		country,
		pincode,
	} = payload.data;
	try {
		let modifyEmployeeValue = yield call(
			modifyEmployeeOperation,
			name,
			gender,
			dob,
			address,
			city,
			state,
			country,
			pincode
		);
		console.log(modifyEmployeeValue);
		console.log(modifyEmployeeValue.response);
		if (modifyEmployeeValue.response) {
			if (modifyEmployeeValue.response.data.type === 0) {
				yield put(
					modifyEmployeeFail(
						modifyEmployeeValue.response.data.errors[0].message
					)
				);
			} else if (modifyEmployeeValue.response.data.type === 1) {
				yield put(
					modifyEmployeeFail(
						modifyEmployeeValue.response.data.message
					)
				);
			} else {
				yield put(
					modifyEmployeeFail(
						modifyEmployeeValue.response.data.message
					)
				);
			}
		} else {
			yield put(
				modifyEmployeeSuccess(modifyEmployeeValue.data.data.description)
			);
			setTimeout(function () {
				window.location.assign("/");
			}, 2000);
		}
	} catch (error) {
		yield put(modifyEmployeeFail(error));
	}
}

export default function* rootSaga() {
	yield all([
		fork(watchModifyEmployee),
		fork(watchLoadEmployeeDetail),
		fork(watchAddEmployee),
		fork(watchAddEmployeeDisputeComment),
		fork(watchLoadEmployeeList),
		fork(watchLoadEmployeePaymentList),
		fork(watchLoadEmployeeDisputeList),
		fork(watchLoadEmployeeDisputeDetail)
	]);
}
