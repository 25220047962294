import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { VIEW_STATES } from "../actions";
import { fetchStatesSuccessfull } from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchLoadStates() {
	yield takeEvery(VIEW_STATES, loadStates);
	// yield call(loadStates);
}

const getStates = async (country) => {
	try {
		const response = await axios.get(
			`${API_URL}locations/state/list/${country}`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadStates({ payload }) {
	console.log(payload);
	const { country } = payload;
	try {
		const states = yield call(getStates, country);
		if (states) {
			yield put(fetchStatesSuccessfull(states));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		// return error;
		console.log(error);
	}
}

// export function* watchLoadStates() {
// 	yield take(VIEW_STATES);
// 	yield call(loadStates);
// }

export default function* rootSaga() {
	yield all([fork(watchLoadStates)]);
}
