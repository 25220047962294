import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
	FETCH_AEPS_COMMISION_ADMIN,
	FETCH_DMT_COMMISION_ADMIN,
	FETCH_DTH_COMMISION_ADMIN,
	FETCH_ELECTRICITY_COMMISION_ADMIN,
	FETCH_MOBILE_POSTPAID_COMMISION_ADMIN,
	FETCH_MOBILE_PREPAID_COMMISION_ADMIN,
	FETCH_PAN_COMMISION_ADMIN,
	UPDATE_ADMIN_AEPS_COMMISSION,
	UPDATE_ADMIN_DMT_COMMISSION,
	UPDATE_ADMIN_DTH_COMMISSION,
	UPDATE_ADMIN_ELECTRICITY_COMMISSION,
	UPDATE_ADMIN_POSTPAID_COMMISSION,
	UPDATE_ADMIN_PREPAID_COMMISSION,
	UPDATE_ADMIN_PAN_COMMISSION,
	UPLOAD_PROFILE_PIC_EMPLOYEE,
	FETCH_PROFILE_PIC_EMPLOYEE,
} from "../actions";
import {
	fetchAEPSCommision,
	fetchElectricityCommission,
	fetchDMTCommision,
	fetchDTHCommision,
	fetchMobilePostpaidCommission,
	fetchMobilePrepaidCommission,
	fetchPanCommission,
	udpateAEPSCommissionFail,
	updateAEPSCommissionsuccess,
	updateDMTCommissionFail,
	updateDMTCommissionSuccess,
	updateDTHCommissionFail,
	updateDTHCommissionSuccess,
	updateElectricityCommissionFail,
	updateElectricityCommissionSuccess,
	updatePostpaidCommissionFail,
	updatePostpaidCommissionSuccess,
	updatePrepaidCommissionFail,
	updatePrepaidCommissionSuccess,
	updatePanCommissionFail,
	updatePanCommissionSuccess,
	uploadProfilePicFail,
	uploadProfilePicSuccess,
	fetchProfilePic,
} from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

/*****************FETCH PROFILE PIC**************/
export function* watchFetchProfilePic() {
	while (true) {
		yield take(FETCH_PROFILE_PIC_EMPLOYEE);
		yield call(loadProfilePic);
	}
}

const getProfilePic = async () => {
	try {
		const response = await axios.get(
			`${API_URL}users/employees/my/profile/image`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadProfilePic() {
	try {
		const ProfilepicVar = yield call(getProfilePic);
		console.log(ProfilepicVar);
		if (ProfilepicVar) {
			yield put(fetchProfilePic(ProfilepicVar));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/***************UPLOAD PROFILE PIC**************/
export function* watchUploadProfilePic() {
	yield takeEvery(UPLOAD_PROFILE_PIC_EMPLOYEE, uploadProfilePic);
}

const newUploadProfilePic = async (employee_image) => {
	try {
		const response = await axios.post(
			`${API_URL}users/employees/my/profile/image/upload`,
			// { AuthToken },
			{
				employee_image,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* uploadProfilePic({ payload }) {
	const { employee_image } = payload.profilePic;
	// const { history } = payload;
	try {
		const uploadProfilePicvar = yield call(
			newUploadProfilePic,
			employee_image
		);

		if (uploadProfilePicvar.response) {
			if (uploadProfilePicvar.response.data.type === 2) {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.message
					)
				);
			} else if (uploadProfilePicvar.response.data.type === 1) {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.message
					)
				);
			} else {
				yield put(
					uploadProfilePicFail(
						uploadProfilePicvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				uploadProfilePicSuccess(
					uploadProfilePicvar.data.data.description
				)
			);
			// setTimeout(function () {
			// 	history.push("/admin/basecommissions/aeps_commisions");
			// }, 3000);
		}
	} catch (error) {
		yield put(uploadProfilePicFail(error));
	}
}

/****************UPDATE AEPS COMMISSION**************/
export function* watchUpdateAEPSCommission() {
	yield takeEvery(UPDATE_ADMIN_AEPS_COMMISSION, updateAEPSCommission);
}

const newUpdateAEPSCommission = async (
	slab,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/aeps/${slab}`,
			// { AuthToken },
			{
				slab,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updateAEPSCommission({ payload }) {
	const {
		slab,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updateAEPSCommissionvar = yield call(
			newUpdateAEPSCommission,
			slab,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updateAEPSCommissionvar.response) {
			if (updateAEPSCommissionvar.response.data.type === 2) {
				yield put(
					udpateAEPSCommissionFail(
						updateAEPSCommissionvar.response.data.message
					)
				);
			} else if (updateAEPSCommissionvar.response.data.type === 1) {
				yield put(
					udpateAEPSCommissionFail(
						updateAEPSCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					udpateAEPSCommissionFail(
						updateAEPSCommissionvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				updateAEPSCommissionsuccess(
					updateAEPSCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push("/admin/basecommissions/aeps_commisions");
			}, 3000);
		}
	} catch (error) {
		yield put(udpateAEPSCommissionFail(error));
	}
}

/**************UPDATE DMT COMMISSION******/
export function* watchUpdateDMTCommission() {
	yield takeEvery(UPDATE_ADMIN_DMT_COMMISSION, updateDMTCommission);
}

const newUpdateDMTCommission = async (
	slab,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/dmt/${slab}`,
			// { AuthToken },
			{
				slab,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updateDMTCommission({ payload }) {
	const {
		slab,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updateDMTCommissionvar = yield call(
			newUpdateDMTCommission,
			slab,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updateDMTCommissionvar.response) {
			if (updateDMTCommissionvar.response.data.type === 2) {
				yield put(
					updateDMTCommissionFail(
						updateDMTCommissionvar.response.data.message
					)
				);
			} else if (updateDMTCommissionvar.response.data.type === 1) {
				yield put(
					updateDMTCommissionFail(
						updateDMTCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updateDMTCommissionFail(
						updateDMTCommissionvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				updateDMTCommissionSuccess(
					updateDMTCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push("/admin/basecommissions/dmt_commisions");
			}, 3000);
		}
	} catch (error) {
		yield put(updateDMTCommissionFail(error));
	}
}

/************UPDATE DTH COMMISSION***********/
export function* watchUpdateDTHCommission() {
	yield takeEvery(UPDATE_ADMIN_DTH_COMMISSION, updateDTHCommission);
}

const newUpdateDTHCommission = async (
	opearator,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/dth/${opearator}`,
			// { AuthToken },
			{
				opearator,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updateDTHCommission({ payload }) {
	const {
		opearator,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updateDTHCommissionvar = yield call(
			newUpdateDTHCommission,
			opearator,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updateDTHCommissionvar.response) {
			if (updateDTHCommissionvar.response.data.type === 2) {
				yield put(
					updateDTHCommissionFail(
						updateDTHCommissionvar.response.data.message
					)
				);
			} else if (updateDTHCommissionvar.response.data.type === 1) {
				yield put(
					updateDTHCommissionFail(
						updateDTHCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updateDTHCommissionFail(
						updateDTHCommissionvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				updateDTHCommissionSuccess(
					updateDTHCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push("/admin/basecommissions/dth_commisions");
			}, 3000);
		}
	} catch (error) {
		yield put(updateDTHCommissionFail(error));
	}
}

/****************UPDATE ELECTRICITY COMMISSION**********/
export function* watchUpdateElectricityCommission() {
	yield takeEvery(
		UPDATE_ADMIN_ELECTRICITY_COMMISSION,
		updateElectricityCommission
	);
}

const newUpdateElectricityCommission = async (
	opearator,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/electricity/${opearator}`,
			// { AuthToken },
			{
				opearator,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updateElectricityCommission({ payload }) {
	const {
		opearator,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updateElectricityCommissionvar = yield call(
			newUpdateElectricityCommission,
			opearator,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updateElectricityCommissionvar.response) {
			if (updateElectricityCommissionvar.response.data.type === 2) {
				yield put(
					updateElectricityCommissionFail(
						updateElectricityCommissionvar.response.data.message
					)
				);
			} else if (
				updateElectricityCommissionvar.response.data.type === 1
			) {
				yield put(
					updateElectricityCommissionFail(
						updateElectricityCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updateElectricityCommissionFail(
						updateElectricityCommissionvar.response.data.errors[0]
							.message
					)
				);
			}
		} else {
			yield put(
				updateElectricityCommissionSuccess(
					updateElectricityCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push("/admin/basecommissions/electricity_commisions");
			}, 3000);
		}
	} catch (error) {
		yield put(updateElectricityCommissionFail(error));
	}
}

/**************UPDATE POSTPAID COMMISSION**************/
export function* watchUpdatePostpaidCommission() {
	yield takeEvery(UPDATE_ADMIN_POSTPAID_COMMISSION, updatePostpaidCommission);
}

const newUpdatePostpaidCommission = async (
	opearator,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/mobile_postpaid/${opearator}`,
			// { AuthToken },
			{
				opearator,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updatePostpaidCommission({ payload }) {
	const {
		opearator,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updatePostpaidCommissionvar = yield call(
			newUpdatePostpaidCommission,
			opearator,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updatePostpaidCommissionvar.response) {
			if (updatePostpaidCommissionvar.response.data.type === 2) {
				yield put(
					updatePostpaidCommissionFail(
						updatePostpaidCommissionvar.response.data.message
					)
				);
			} else if (updatePostpaidCommissionvar.response.data.type === 1) {
				yield put(
					updatePostpaidCommissionFail(
						updatePostpaidCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updatePostpaidCommissionFail(
						updatePostpaidCommissionvar.response.data.errors[0]
							.message
					)
				);
			}
		} else {
			yield put(
				updatePostpaidCommissionSuccess(
					updatePostpaidCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push(
					"/admin/basecommissions/mobile_postpaid_commisions"
				);
			}, 3000);
		}
	} catch (error) {
		yield put(updatePostpaidCommissionFail(error));
	}
}

/****************UPDATE PREPAID COMMISSION*********/
export function* watchUpdatePrepaidCommission() {
	yield takeEvery(UPDATE_ADMIN_PREPAID_COMMISSION, updatePrepaidCommission);
}

const newUpdatePrepaidCommission = async (
	opearator,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/mobile_prepaid/${opearator}`,
			// { AuthToken },
			{
				opearator,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updatePrepaidCommission({ payload }) {
	const {
		opearator,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updatePrepaidCommissionvar = yield call(
			newUpdatePrepaidCommission,
			opearator,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updatePrepaidCommissionvar.response) {
			if (updatePrepaidCommissionvar.response.data.type === 2) {
				yield put(
					updatePrepaidCommissionFail(
						updatePrepaidCommissionvar.response.data.message
					)
				);
			} else if (updatePrepaidCommissionvar.response.data.type === 1) {
				yield put(
					updatePostpaidCommissionFail(
						updatePrepaidCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updatePostpaidCommissionFail(
						updatePrepaidCommissionvar.response.data.errors[0]
							.message
					)
				);
			}
		} else {
			yield put(
				updatePrepaidCommissionSuccess(
					updatePrepaidCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push(
					"/admin/basecommissions/mobile_prepaid_commisions"
				);
			}, 3000);
		}
	} catch (error) {
		yield put(updatePostpaidCommissionFail(error));
	}
}

/****************UPDATE PAN COMMISSION**********/
export function* watchUpdatePanCommission() {
	yield takeEvery(UPDATE_ADMIN_PAN_COMMISSION, updatePanCommission);
}

const newUpdatePanCommission = async (
	opearator,
	commission_type,
	retailer_base_commission,
	retailer_max_commission,
	distributor_base_commission,
	distributor_max_commission,
	company_commission
) => {
	try {
		const response = await axios.put(
			`${API_URL}commissions/company/base_commission/pan/${opearator}`,
			// { AuthToken },
			{
				opearator,
				commission_type,
				retailer_base_commission,
				retailer_max_commission,
				distributor_base_commission,
				distributor_max_commission,
				company_commission,
			}
		);

		return response;
	} catch (error) {
		return error;
	}
};

function* updatePanCommission({ payload }) {
	const {
		opearator,
		commission_type,
		retailer_base_commission,
		retailer_max_commission,
		distributor_base_commission,
		distributor_max_commission,
		company_commission,
	} = payload.commissionData;
	const { history } = payload;
	try {
		const updatePanCommissionvar = yield call(
			newUpdatePanCommission,
			opearator,
			commission_type,
			retailer_base_commission,
			retailer_max_commission,
			distributor_base_commission,
			distributor_max_commission,
			company_commission
		);

		if (updatePanCommissionvar.response) {
			if (updatePanCommissionvar.response.data.type === 2) {
				yield put(
					updatePanCommissionFail(
						updatePanCommissionvar.response.data.message
					)
				);
			} else if (updatePanCommissionvar.response.data.type === 1) {
				yield put(
					updatePanCommissionFail(
						updatePanCommissionvar.response.data.message
					)
				);
			} else {
				yield put(
					updatePanCommissionFail(
						updatePanCommissionvar.response.data.errors[0].message
					)
				);
			}
		} else {
			yield put(
				updatePanCommissionSuccess(
					updatePanCommissionvar.data.data.description
				)
			);
			setTimeout(function () {
				history.push("/admin/basecommissions/pan_commisions");
			}, 3000);
		}
	} catch (error) {
		yield put(updatePostpaidCommissionFail(error));
	}
}

/*************LOAD AEPS COMMISSION*******/
export function* watchLoadAEPSCommision() {
	while (true) {
		yield take(FETCH_AEPS_COMMISION_ADMIN);
		yield call(loadAEPSCOmmision);
	}
}

const getAEPSCOmmision = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/aeps`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadAEPSCOmmision() {
	try {
		const AEPSCOmmision = yield call(getAEPSCOmmision);
		if (AEPSCOmmision) {
			yield put(fetchAEPSCommision(AEPSCOmmision));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/**************LOAD DMT COMMISSIONS***/
export function* watchLoadDMTCommision() {
	while (true) {
		yield take(FETCH_DMT_COMMISION_ADMIN);
		yield call(loadDMTCommission);
	}
}

const getDMTCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/dmt`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadDMTCommission() {
	try {
		const DMTCommission = yield call(getDMTCommission);
		if (DMTCommission) {
			yield put(fetchDMTCommision(DMTCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/**************LAOD DTH COMMISSION*****/
export function* watchLoadDTHCommision() {
	while (true) {
		yield take(FETCH_DTH_COMMISION_ADMIN);
		yield call(loadDTHCommission);
	}
}

const getDTHCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/dth`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadDTHCommission() {
	try {
		const DTHCommission = yield call(getDTHCommission);
		if (DTHCommission) {
			yield put(fetchDTHCommision(DTHCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

/****************LOAD ELECTRICITY COMMISION***********/
export function* watchLoadElectricityCommision() {
	while (true) {
		yield take(FETCH_ELECTRICITY_COMMISION_ADMIN);
		yield call(loadElectrictyCommission);
	}
}

const getElectricityCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/electricity`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadElectrictyCommission() {
	try {
		const ElectricityCommission = yield call(getElectricityCommission);
		if (ElectricityCommission) {
			yield put(fetchElectricityCommission(ElectricityCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************LOAD MOBILE POSTPAID COMMISSION*********/
export function* watchLoadMobilePostpaidCommision() {
	while (true) {
		yield take(FETCH_MOBILE_POSTPAID_COMMISION_ADMIN);
		yield call(loadMobilePostpaidCommission);
	}
}

const getMobilePostpaidCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/mobile_postpaid`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadMobilePostpaidCommission() {
	try {
		const MobilePostpaidCommission = yield call(
			getMobilePostpaidCommission
		);
		if (MobilePostpaidCommission) {
			yield put(fetchMobilePostpaidCommission(MobilePostpaidCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/****************LOAD MOBILE PREPAID COMMISSION**********/
export function* watchLoadMobilePrepaidCommision() {
	while (true) {
		yield take(FETCH_MOBILE_PREPAID_COMMISION_ADMIN);
		yield call(loadMobilePrepaidCommission);
	}
}

const getMobilePrepaidCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/mobile_prepaid`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadMobilePrepaidCommission() {
	try {
		const MobilePrepaidCommission = yield call(getMobilePrepaidCommission);
		if (MobilePrepaidCommission) {
			yield put(fetchMobilePrepaidCommission(MobilePrepaidCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}
/*************LOAD PAN COMMISSION*****/
export function* watchLoadPanCommission() {
	while (true) {
		yield take(FETCH_PAN_COMMISION_ADMIN);
		yield call(loadPanCommission);
	}
}

const getPanCommission = async () => {
	try {
		const response = await axios.get(
			`${API_URL}commissions/company/base_commission/pan`,
			{ AuthToken }
		);

		return response.data.data.items;
	} catch (error) {
		return error;
	}
};

export function* loadPanCommission() {
	try {
		const PanCommission = yield call(getPanCommission);
		if (PanCommission) {
			yield put(fetchPanCommission(PanCommission));
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		return error;
		// console.log(error);
	}
}

export default function* rootSaga() {
	yield all([
		fork(watchLoadAEPSCommision),
		fork(watchLoadDMTCommision),
		fork(watchLoadDTHCommision),
		fork(watchLoadElectricityCommision),
		fork(watchLoadMobilePostpaidCommision),
		fork(watchLoadMobilePrepaidCommision),
		fork(watchLoadPanCommission),
		fork(watchUpdateAEPSCommission),
		fork(watchUpdateDMTCommission),
		fork(watchUpdateDTHCommission),
		fork(watchUpdateElectricityCommission),
		fork(watchUpdatePostpaidCommission),
		fork(watchUpdatePrepaidCommission),
		fork(watchUpdatePanCommission),
		fork(watchUploadProfilePic),
		fork(watchFetchProfilePic),
	]);
}
