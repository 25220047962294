import { combineReducers } from "redux";
import settings from "./settings/reducer";
import menu from "./menu/reducer";
import authUser from "./auth/reducer";
import distributor from "./distributor/reducer";
import retailer from "./retailer/reducer";
import state from "./state/reducer";
import city from "./city/reducer";
import country from "./country/reducer";
import employee from "./employee/reducer";
import admin from "./admin/reducer";
import download from "./download/reducer";
import driver from "./driver/reducer";
import desktoptool from "./desktoptool/reducer";
import transaction from "./transaction/reducer";
import report from "./report/reducer";
const reducers = combineReducers({
	menu,
	settings,
	authUser,
	distributor,
	retailer,
	state,
	city,
	country,
	employee,
	admin,
	download,
	driver,
	desktoptool,
	transaction,
	report,
});

export default reducers;
