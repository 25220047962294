import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    // FORGOT_PASSWORD,
    RESET_PASSWORD,
} from "../actions";

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    // forgotPasswordSuccess,
    // forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
} from "./actions";

import { API_URL } from "../../api/ApiURL";

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = async (username, password, login_type) =>
    //     let response = await fetch(
    //         `http://paisahub.infraconmarket.com/dev/paisahub/api/users/admins/login`
    //     );
    //     let data = await response.json();
    //     console.log(data);
    //     return data;
    // };

    {
        console.log(login_type);
        if (login_type === "Admin") {
            try {
                const response = await axios.post(
                    `${API_URL}users/employees/login`,
                    { username, password }
                );
                console.log("adminloginresponse", response);
                return response;
                // yield put(
                //     loginUserSuccess(loginUser.response.data.data.description)
                // );
            } catch (errors) {
                console.log("autherror", errors.response.data.message);

                return errors;
            }
        }
        if (login_type === "Employee") {
            try {
                const response = await axios.post(
                    `${API_URL}users/user/login`,
                    { username, password }
                );
                console.log("distributorloginresponse", response);
                return response;
                // yield put(
                //     loginUserSuccess(loginUser.response.data.data.description)
                // );
            } catch (errors) {
                console.log("autherror", errors.response.data.message);

                return errors;
            }
        }
        // if (login_type === "Retailer") {
        //     try {
        //         const response = await axios.post(
        //             `${API_URL}users/retailers/login`,
        //             { username, password }
        //         );
        //         console.log("retailerloginresponse", response);
        //         return response;
        //         // yield put(
        //         //     loginUserSuccess(loginUser.response.data.data.description)
        //         // );
        //     } catch (errors) {
        //         console.log("autherror", errors.response.data.message);

        //         return errors;
        //     }
        // }
    };
// await fetch(
//     "http://infraconmarket.com/dev/paisahub/api/users/admins/login",
//     {
//         method: "POST",
//         body: JSON.stringify({
//             username,
//             password,
//         }),
//         headers: {
//             "Content-type": "application/json; charset=UTF-8",
//         },
//     }
// )
//     .then((res) => res.json())
//     .then((res) => {
//         console.log(res);
//         return res;
//     })
// .catch((error) => error);
// auth
//     .signInWithEmailAndPassword(email, password) //API
//     .then((authUser) => authUser)
//     .catch((error) => error);

function* loginWithEmailPassword({ payload }) {
    console.log(payload);
    const { username, password, login_type } = payload.user;
    // const { history } = payload;
    try {
        const loginUser = yield call(
            loginWithEmailPasswordAsync,
            username,
            password,
            login_type
        );
        if (!loginUser.message) {
            console.log("something admin login", loginUser.response);
            // adminAuth = !adminAuth;
            sessionStorage.setItem("user_id", loginUser.headers["x-id-token"]);
            sessionStorage.setItem("adminAuth", true);
            sessionStorage.setItem(
                "adminusername",
                loginUser.data.data.items[0].name
            );
            sessionStorage.setItem("kind", loginUser.data.data.kind);
            yield put(loginUserSuccess(loginUser));
            if (login_type === "Admin") {
                window.location.assign("/");
            }
            if (login_type === "Employee") {
                window.location.assign("/employee");
            }
            // history.push("/");

            console.log("abouvefuncres", loginUser.response);
            yield put(
                loginUserSuccess(loginUser.response.data.data.description)
            );
            // history.push("/admin");
        } else {
            console.log("abouvefuncres", loginUserSuccess);
            yield put(loginUserError(loginUser.response.data.message));
        }
    } catch (error) {
        console.log(error);
        yield put(loginUserError(error));
    }
}

export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

// const registerWithEmailPasswordAsync = async (email, password) =>
//     await auth
//         .createUserWithEmailAndPassword(email, password)
//         .then((authUser) => authUser)
//         .catch((error) => error);

function* registerWithEmailPassword({ payload }) {
    const { email, password } = payload.user;
    const { history } = payload;
    try {
        const registerUser = yield call(
            // registerWithEmailPasswordAsync,
            email,
            password
        );
        if (!registerUser.message) {
            localStorage.setItem("user_id", registerUser.user.uid);
            yield put(registerUserSuccess(registerUser));
            history.push("/");
        } else {
            yield put(registerUserError(registerUser.message));
        }
    } catch (error) {
        yield put(registerUserError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await sessionStorage.clear();
    window.location.assign("/");
    // history.push("/");
    // await auth
    //     .signOut()
    //     .then((authUser) => authUser)
    //     .catch((error) => error);
    // history.push("/");
};

function* logout({ payload }) {
    const { history } = payload;
    try {
        yield call(logoutAsync, history);
        // localStorage.removeItem("user_id");
        sessionStorage.clear();
        window.location.assign("/");
    } catch (error) {}
}

// export function* watchForgotPassword() {
//     yield takeEvery(FORGOT_PASSWORD, forgotPassword);
// }

// const forgotPasswordAsync = async (email) => {
//     return await auth
//         .sendPasswordResetEmail(email)
//         .then((user) => user)
//         .catch((error) => error);
// };

// function* forgotPassword({ payload }) {
//     const { email } = payload.forgotUserMail;
//     try {
//         const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
//         if (!forgotPasswordStatus) {
//             yield put(forgotPasswordSuccess("success"));
//         } else {
//             yield put(forgotPasswordError(forgotPasswordStatus.message));
//         }
//     } catch (error) {
//         yield put(forgotPasswordError(error));
//     }
// }

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (new_password, current_password) => {
    if (sessionStorage.getItem("kind") === "Distributor login") {
        try {
            const response = await axios.put(
                `${API_URL}users/distributors/password`,
                {
                    new_password,
                    current_password,
                }
            );

            return response;
        } catch (errors) {
            return errors;
        }
    } else {
        try {
            const response = await axios.put(
                `${API_URL}users/employees/password`,
                {
                    new_password,
                    current_password,
                }
            );

            return response;
        } catch (errors) {
            return errors;
        }
    }
};

function* resetPassword({ payload }) {
    console.log(payload);
    const { new_password, current_password } = payload.passwordValues;
    // const { history } = payload;
    try {
        const resetPasswordStatus = yield call(
            resetPasswordAsync,
            new_password,
            current_password
        );

        if (resetPasswordStatus.response) {
            if (resetPasswordStatus.response.data.type === 0) {
                yield put(
                    resetPasswordError(
                        resetPasswordStatus.response.data.errors[0].message
                    )
                );
            } else if (resetPasswordStatus.response.data.type === 2) {
                yield put(
                    resetPasswordError(
                        resetPasswordStatus.response.data.message
                    )
                );
            } else if (resetPasswordStatus.response.data.type === 3) {
                yield put(
                    resetPasswordError(
                        resetPasswordStatus.response.data.message
                    )
                );
            } else {
                yield put(
                    resetPasswordError(
                        resetPasswordStatus.response.data.message
                    )
                );
            }
        } else {
            yield put(
                resetPasswordSuccess(resetPasswordStatus.data.data.description)
            );
            setTimeout(function () {
                sessionStorage.clear();
                window.location.assign("/");
            }, 2000);
        }
        // if (!resetPasswordStatus) {
        //     yield put(resetPasswordSuccess("success"));
        // } else {
        //     yield put(resetPasswordError(resetPasswordStatus.message));
        // }
    } catch (error) {
        yield put(resetPasswordError(error));
    }
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        // fork(watchForgotPassword),
        fork(watchResetPassword),
    ]);
}
