import { FETCH_REPORT_EMPLOYEE, FETCH_REPORT_EMPLOYEE_SUCCESS } from "../actions";

const INIT_STATE = { report: [], totalPages: "", currentpage: 1 };

const driver = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_REPORT_EMPLOYEE:
			return {
				...state,
			};
		case FETCH_REPORT_EMPLOYEE_SUCCESS:
			return {
				...state,
				report: action.payload.report,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
			};
		default:
			return state;
	}
};

export default driver;
