import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { FETCH_DRIVER } from "../actions";
import { fetchDriversListsSuccess } from "./actions";
import { API_URL } from "../../api/ApiURL";
import { AuthToken } from "../../constants/authToken";

export function* watchLoadDrivers() {
	yield takeEvery(FETCH_DRIVER, loadDrivers);
}

const getDrivers = async (currentPage, pageLimit) => {
	try {
		const response = await axios.get(
			`${API_URL}downloads/list?type=Driver&page_no=${currentPage}&limit=${pageLimit}`,
			{
				AuthToken,
			}
		);

		return response.data.data.items;
	} catch (error) {
		return error.response;
	}
};

export function* loadDrivers({ payload }) {
	const { currentPage, pageLimit } = payload;
	try {
		const drivers = yield call(getDrivers, currentPage, pageLimit);
		if (drivers) {
			yield put(
				fetchDriversListsSuccess(
					drivers[0].downloadLinks,
					drivers[0].numberOfPages,
					drivers[0].currentPageNumber
				)
			);
		} else {
			console.log("something is worng");
		}
	} catch (error) {
		// return error;
		console.log(error);
	}
}
export default function* rootSaga() {
	yield all([fork(watchLoadDrivers)]);
}
