import {
	ADD_EMPLOYEE,
	ADD_EMPLOYEE_SUCCESS,
	ADD_EMPLOYEE_FAIL,
	VIEW_EMPLOYEE_DETAIL,
	MODIFY_EMPLOYEE,
	MODIFY_EMPLOYEE_FAIL,
	MODIFY_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_LIST,
	FETCH_EMPLOYEE_LIST_SUCCESS,
	FETCH_EMPLOYEE_LIST_FAIL,
	FETCH_EMPLOYEE_PAYMENT_LIST,
	FETCH_EMPLOYEE_PAYMENT_LIST_FAIL,
	FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS,
	FETCH_EMPLOYEE_DISPUTE_LIST,
	FETCH_EMPLOYEE_DISPUTE_LIST_FAIL,
	FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS,
	ADD_EMPLOYEE_DISPUTE_COMMENT,
	ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL,
	ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS,
			FETCH_EMPLOYEE_DISPUTE_DETAIL,
	FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL,
	FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS,

} from "../actions";

const INIT_STATE = {
	loading: false,
	modify_success_message: "",
	add_employee_success_message: "",
	error: "",
	employeeDetail: [],
	employees: [],
	paymentList: [],
	disputeList: [],
	disputeDetail : [],
	add_dispute_comment_success_message : "",
	totalPages: "",
	currentpage: 1,
};

const employee = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_EMPLOYEE_LIST:
			return {
				...state,
				error: "",
			};
		case FETCH_EMPLOYEE_LIST_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case FETCH_EMPLOYEE_LIST_SUCCESS:
			return {
				...state,
				employees: action.payload.employees,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
				error: "",
			};
		case FETCH_EMPLOYEE_PAYMENT_LIST:
			return {
				...state,
				error: "",
			};
		case FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS:
			return {
				...state,
				paymentList: action.payload.paymentList,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
				error: "",
			};
		case FETCH_EMPLOYEE_PAYMENT_LIST_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
		case FETCH_EMPLOYEE_DISPUTE_LIST:
			return {
				...state,
				error: "",
			};
		case FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS:
			return {
				...state,
				disputeList: action.payload.disputeList,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
				error: "",
			};
		case FETCH_EMPLOYEE_DISPUTE_LIST_FAIL:
			return {
				...state,
				error: action.payload.message,
			};
			case FETCH_EMPLOYEE_DISPUTE_DETAIL:
			return {
				...state,
				error: "",
				loading: true,
			};
		case FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS:
			return {
				...state,
				disputeDetail: action.payload.disputeDetail,
				error: "",
				loading: false,
			};
		case FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL:
			return {
				...state,
				error: action.payload.message,
				loading: false,
			};
		case VIEW_EMPLOYEE_DETAIL:
			return {
				...state,
				employeeDetail: action.payload.detail,
				error: "",
			};
		case ADD_EMPLOYEE:
			return {
				...state,
				error: "",
				loading: true,
			};
		case ADD_EMPLOYEE_SUCCESS:
			return {
				...state,
				loading: false,
				add_employee_success_message: action.payload.message,
				error: "",
			};
		case ADD_EMPLOYEE_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.message,
				add_employee_success_message: "",
			};
		case MODIFY_EMPLOYEE:
			return {
				...state,
				error: "",
				data: action.payload.data,
				loading: true,
			};
		case MODIFY_EMPLOYEE_SUCCESS:
			return {
				...state,
				modify_success_message: action.payload.message,
				loading: false,
				error: "",
			};
		case MODIFY_EMPLOYEE_FAIL:
			return {
				...state,
				modify_success_message: "",
				error: action.payload.message,
				loading: false,
			};
			case ADD_EMPLOYEE_DISPUTE_COMMENT:
			return {
				...state,
				loading: true,
				error: "",
				add_dispute_comment_success_message : ""
			};
		case ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.message,
				add_dispute_comment_success_message: "",
			};
		case ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS:
			return {
				...state,
				error: "",
				loading: false,
				add_dispute_comment_success_message: action.payload.message,
			};
		default:
			return state;
	}
};

export default employee;
