import {
	ADD_EMPLOYEE,
	ADD_EMPLOYEE_SUCCESS,
	ADD_EMPLOYEE_FAIL,
	VIEW_EMPLOYEE_DETAIL,
	MODIFY_EMPLOYEE,
	MODIFY_EMPLOYEE_FAIL,
	MODIFY_EMPLOYEE_SUCCESS,
	FETCH_EMPLOYEE_LIST,
	FETCH_EMPLOYEE_LIST_SUCCESS,
	FETCH_EMPLOYEE_LIST_FAIL,
	FETCH_EMPLOYEE_PAYMENT_LIST,
	FETCH_EMPLOYEE_PAYMENT_LIST_FAIL,
	FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS,
	FETCH_EMPLOYEE_DISPUTE_LIST,
	FETCH_EMPLOYEE_DISPUTE_LIST_FAIL,
	FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS,
	ADD_EMPLOYEE_DISPUTE_COMMENT,
	ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL,
	ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS,
		FETCH_EMPLOYEE_DISPUTE_DETAIL,
	FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL,
	FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS,
} from "../actions";

export const fetchEmployeeList = (currentPage, pageLimit) => ({
	type: FETCH_EMPLOYEE_LIST,
	payload: { currentPage, pageLimit },
});

export const fetchEmployeeListFail = (message) => ({
	type: FETCH_EMPLOYEE_LIST_FAIL,
	payload: { message },
});

export const fetchEmployeeListSuccess = (
	employees,
	totalPages,
	currentpage
) => ({
	type: FETCH_EMPLOYEE_LIST_SUCCESS,
	payload: { employees, totalPages, currentpage },
});

export const fetchEmployeeDetail = (detail) => ({
	type: VIEW_EMPLOYEE_DETAIL,
	payload: { detail },
});

export const addEmployee = (employeeValue) => ({
	type: ADD_EMPLOYEE,
	payload: { employeeValue },
});

export const addEmployeeSuccess = (message) => ({
	type: ADD_EMPLOYEE_SUCCESS,
	payload: { message },
});

export const addEmployeeFail = (message) => ({
	type: ADD_EMPLOYEE_FAIL,
	payload: { message },
});

export const modifyEmployee = (data) => ({
	type: MODIFY_EMPLOYEE,
	payload: { data },
});

export const modifyEmployeeSuccess = (message) => ({
	type: MODIFY_EMPLOYEE_SUCCESS,
	payload: { message },
});

export const modifyEmployeeFail = (message) => ({
	type: MODIFY_EMPLOYEE_FAIL,
	payload: { message },
});

export const fetchPaymentList = (currentPage, pageLimit) => ({
	type: FETCH_EMPLOYEE_PAYMENT_LIST,
	payload: { currentPage, pageLimit },
});

export const fetchPaymentListFail = (message) => ({
	type: FETCH_EMPLOYEE_PAYMENT_LIST_FAIL,
	payload: { message },
});

export const fetchPaymentListSuccess = (
	paymentList,
	totalPages,
	currentpage
) => ({
	type: FETCH_EMPLOYEE_PAYMENT_LIST_SUCCESS,
	payload: { paymentList, totalPages, currentpage },
});

export const fetchDisputeList = (currentPage, pageLimit) => ({
	type: FETCH_EMPLOYEE_DISPUTE_LIST,
	payload: { currentPage, pageLimit },
});

export const fetchDisputeListFail = (message) => ({
	type: FETCH_EMPLOYEE_DISPUTE_LIST_FAIL,
	payload: { message },
});

export const fetchDisputeListSuccess = (
	disputeList,
	totalPages,
	currentpage
) => ({
	type: FETCH_EMPLOYEE_DISPUTE_LIST_SUCCESS,
	payload: { disputeList, totalPages, currentpage },
});

export const addEmployeeDisputeComment = (employeeCommentValues) => ({
	type: ADD_EMPLOYEE_DISPUTE_COMMENT,
	payload: { employeeCommentValues },
});

export const addEmployeeDisputeCommentFail = (message) => ({
	type: ADD_EMPLOYEE__DISPUTE__COMMENT_FAIL,
	payload: { message },
});

export const addEmployeeDisputeCommentSuccess = (message) => ({
	type: ADD_EMPLOYEE__DISPUTE__COMMENT_SUCCESS,
	payload: { message },
});
export const fetchDisputeDetails = (disputeId) => ({
	type: FETCH_EMPLOYEE_DISPUTE_DETAIL,
	payload: { disputeId },
});

export const fetchDisputeDetailsFail = (message) => ({
	type: FETCH_EMPLOYEE_DISPUTE_DETAIL_FAIL,
	payload: { message },
});

export const fetchDisputeDetailsSuccess = (disputeDetail) => ({
	type: FETCH_EMPLOYEE_DISPUTE_DETAIL_SUCCESS,
	payload: { disputeDetail },
});