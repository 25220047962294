import { VIEW_STATES, VIEW_STATES_SUCCESSFULL } from "../actions";

const INIT_STATE = { states: [], country: {} };

const state = (state = INIT_STATE, action) => {
	switch (action.type) {
		case VIEW_STATES:
			return {
				...state,
				country: action,
			};
		case VIEW_STATES_SUCCESSFULL:
			console.log("viewstatesuccess", action);
			return {
				...state,
				states: action.payload.states,
			};
		default:
			return state;
	}
};

export default state;
