import { FETCH_DRIVER, FETCH_DRIVER_SUCCESS } from "../actions";

export const fetchDriver = (currentPage, pageLimit) => ({
	type: FETCH_DRIVER,
	payload: { currentPage, pageLimit },
});

export const fetchDriversListsSuccess = (drivers, totalPages, currentpage) => ({
	type: FETCH_DRIVER_SUCCESS,
	payload: { drivers, totalPages, currentpage },
});
