import {
	FETCH_AEPS_COMMISION,
	FETCH_DMT_COMMISION,
	FETCH_DTH_COMMISION,
	FETCH_ELECTRICITY_COMMISION,
	FETCH_MOBILE_POSTPAID_COMMISION,
	FETCH_MOBILE_PREPAID_COMMISION,
	FETCH_PAN_COMMISION,
	FETCH_FILTER_DISTRIBTUOR,
	FETCH_FILTER_DISTRIBTUOR_FAIL,
	FETCH_FILTER_DISTRIBTUOR_SUCCESS,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL,
	FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
	ADD_DISTRIBUTERS,
	ADD_DISTRIBUTERS_FAIL,
	ADD_DISTRIBUTERS_SUCCESS,
	FETCH_DISTRIBUTORS_LIST,
	FETCH_DISTRIBUTORS_LIST_FAIL,
	FETCH_DISTRIBUTORS_LIST_SUCCESS,
	VIEW_DISTRIBUTOR_DETAIL,
	MODIFY_DISTRIBUTOR,
	MODIFY_DISTRIBUTOR_FAIL,
	MODIFY_DISTRIBUTOR_SUCCESS,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL,
	UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS,
} from "../actions";

export const addDistributors = (distributorValues) => ({
	type: ADD_DISTRIBUTERS,
	payload: { distributorValues },
});

export const addDistributorFail = (message) => ({
	type: ADD_DISTRIBUTERS_FAIL,
	payload: { message },
});

export const addDistributorSuccess = (message) => ({
	type: ADD_DISTRIBUTERS_SUCCESS,
	payload: { message },
});

export const fetchAEPSCommision = (AEPSCommisionData) => ({
	type: FETCH_AEPS_COMMISION,
	payload: { AEPSCommisionData },
});

export const fetchDMTCommision = (DMTCommision) => ({
	type: FETCH_DMT_COMMISION,
	payload: { DMTCommision },
});

export const fetchDTHCommision = (DTHCommision) => ({
	type: FETCH_DTH_COMMISION,
	payload: { DTHCommision },
});

export const fetchElectricityCommission = (ElectricityCommission) => ({
	type: FETCH_ELECTRICITY_COMMISION,
	payload: { ElectricityCommission },
});

export const fetchMobilePostpaidCommission = (MobilePostpaidCommission) => ({
	type: FETCH_MOBILE_POSTPAID_COMMISION,
	payload: { MobilePostpaidCommission },
});

export const fetchMobilePrepaidCommission = (MobilePrepaidCommission) => ({
	type: FETCH_MOBILE_PREPAID_COMMISION,
	payload: { MobilePrepaidCommission },
});

export const fetchPanCommission = (PanCommission) => ({
	type: FETCH_PAN_COMMISION,
	payload: { PanCommission },
});

export const fetchFilterDistributor = (distributorId) => ({
	type: FETCH_FILTER_DISTRIBTUOR,
	payload: { distributorId },
});

export const fetchFilterDistributorFail = (message) => ({
	type: FETCH_FILTER_DISTRIBTUOR_FAIL,
	payload: { message },
});

export const fetchFilterDistributorSuccess = (filterDistributor) => ({
	type: FETCH_FILTER_DISTRIBTUOR_SUCCESS,
	payload: { filterDistributor },
});

export const fetchFilterRetailerUnderDistributor = (distributorId) => ({
	type: FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR,
	payload: { distributorId },
});

export const fetchRetailerUnderDistributorFail = (message) => ({
	type: FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_FAIL,
	payload: { message },
});

export const fetchRetailerUnderDistributorSuccess = (filterRetailer) => ({
	type: FETCH_FILTER_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
	payload: { filterRetailer },
});

export const fetchDistributorsList = (currentPage, pageLimit) => ({
	type: FETCH_DISTRIBUTORS_LIST,
	payload: { currentPage, pageLimit },
});

export const fetchDistributorsListFail = (message) => ({
	type: FETCH_DISTRIBUTORS_LIST_FAIL,
	payload: { message },
});

export const fetchDistributorsListSuccess = (
	distributors,
	totalPages,
	currentpage
) => ({
	type: FETCH_DISTRIBUTORS_LIST_SUCCESS,
	payload: { distributors, totalPages, currentpage },
});

export const fetchDistributorDetail = (detail) => ({
	type: VIEW_DISTRIBUTOR_DETAIL,
	payload: { detail },
});

export const modifyDistributor = (data) => ({
	type: MODIFY_DISTRIBUTOR,
	payload: { data },
});

export const modifyDistributorSuccess = (message) => ({
	type: MODIFY_DISTRIBUTOR_SUCCESS,
	payload: { message },
});

export const modifyDistributorFail = (message) => ({
	type: MODIFY_DISTRIBUTOR_FAIL,
	payload: { message },
});

export const uploadProfilePic = (profilePic) => ({
	type: UPLOAD_PROFILE_PIC_DISTRIBUTOR,
	payload: { profilePic },
});

export const uploadProfilePicFail = (message) => ({
	type: UPLOAD_PROFILE_PIC_DISTRIBUTOR_FAIL,
	payload: { message },
});

export const uploadProfilePicSuccess = (message) => ({
	type: UPLOAD_PROFILE_PIC_DISTRIBUTOR_SUCCESS,
	payload: { message },
});
