import { FETCH_TRANSACTION, FETCH_TRANSACTION_SUCCESS } from "../actions";

const INIT_STATE = { transaction: [], totalPages: "", currentpage: 1 };

const driver = (state = INIT_STATE, action) => {
	switch (action.type) {
		case FETCH_TRANSACTION:
			return {
				...state,
			};
		case FETCH_TRANSACTION_SUCCESS:
			return {
				...state,
				transaction: action.payload.transaction,
				totalPages: action.payload.totalPages,
				currentpage: action.payload.currentpage,
			};
		default:
			return state;
	}
};

export default driver;
