import { FETCH_TRANSACTION, FETCH_TRANSACTION_SUCCESS } from "../actions";

export const fetchTransaction = (currentPage, pageLimit) => ({
	type: FETCH_TRANSACTION,
	payload: { currentPage, pageLimit },
});

export const fetchTransactionListsSuccess = (transaction, totalPages, currentpage) => ({
	type: FETCH_TRANSACTION_SUCCESS,
	payload: { transaction, totalPages, currentpage },
});
