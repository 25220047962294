import {
  FETCH_AEPS_COMMISION_RETAILER,
  FETCH_DMT_COMMISION_RETAILER,
  FETCH_DTH_COMMISION_RETAILER,
  FETCH_ELECTRICITY_COMMISION_RETAILER,
  FETCH_MOBILE_POSTPAID_COMMISION_RETAILER,
  FETCH_MOBILE_PREPAID_COMMISION_RETAILER,
  FETCH_PAN_COMMISION_RETAILER,
  FETCH_RETAILER_NOTIFICATION,
  ADD_RETAILER,
  ADD_RETAILER_FAIL,
  ADD_RETAILER_SUCCESS,
  FETCH_RETAILERS_LIST,
  FETCH_RETAILERS_LIST_SUCCESS,
  FETCH_RETAILERS_LIST_FAIL,
  VIEW_RETAILERS_OF_DISTRIBUTORS,
  FETCH_RETAILERS_WALLET,
  FETCH_RETAILER_PROFILE_IMAGE,
  FETCH_RETAILER_PAYMENT_LIST,
  FETCH_RETAILER_PAYMENT_LIST_FAIL,
  FETCH_RETAILER_PAYMENT_LIST_SUCCESS,
  RETAILER_ADD_PAYMENT,
  RETAILER_ADD_PAYMENT_FAIL,
  RETAILER_ADD_PAYMENT_SUCCESS,
  FETCH_RETAILER_DISPUTE_LIST,
  FETCH_RETAILER_DISPUTE_LIST_FAIL,
  FETCH_RETAILER_DISPUTE_LIST_SUCCESS,
  FETCH_RETAILER_DISPUTE_DETAIL,
  FETCH_RETAILER_DISPUTE_DETAIL_FAIL,
  FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS,
  ADD_RETAILER_DISPUTE,
  ADD_RETAILER__DISPUTE_FAIL,
  ADD_RETAILER__DISPUTE_SUCCESS,
  ADD_RETAILER_DISPUTE_COMMENT,
  ADD_RETAILER__DISPUTE__COMMENT_FAIL,
  ADD_RETAILER__DISPUTE__COMMENT_SUCCESS,
  FETCH_RETAILER_UNDER_DISTRIBUTOR,
  FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL,
  FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
} from "../actions";

export const addRetailer = (retailerValues) => ({
  type: ADD_RETAILER,
  payload: { retailerValues },
});

export const addRetailerFail = (message) => ({
  type: ADD_RETAILER_FAIL,
  payload: { message },
});

export const addRetailerSuccess = (message) => ({
  type: ADD_RETAILER_SUCCESS,
  payload: { message },
});

export const fetchAEPSCommision = (AEPSCommisionData) => ({
  type: FETCH_AEPS_COMMISION_RETAILER,
  payload: { AEPSCommisionData },
});

export const fetchDMTCommision = (DMTCommision) => ({
  type: FETCH_DMT_COMMISION_RETAILER,
  payload: { DMTCommision },
});

export const fetchDTHCommision = (DTHCommision) => ({
  type: FETCH_DTH_COMMISION_RETAILER,
  payload: { DTHCommision },
});

export const fetchElectricityCommission = (ElectricityCommission) => ({
  type: FETCH_ELECTRICITY_COMMISION_RETAILER,
  payload: { ElectricityCommission },
});

export const fetchMobilePostpaidCommission = (MobilePostpaidCommission) => ({
  type: FETCH_MOBILE_POSTPAID_COMMISION_RETAILER,
  payload: { MobilePostpaidCommission },
});

export const fetchMobilePrepaidCommission = (MobilePrepaidCommission) => ({
  type: FETCH_MOBILE_PREPAID_COMMISION_RETAILER,
  payload: { MobilePrepaidCommission },
});

export const fetchPanCommission = (PanCommission) => ({
  type: FETCH_PAN_COMMISION_RETAILER,
  payload: { PanCommission },
});

export const fetchNotification = (notificationValues) => ({
  type: FETCH_RETAILER_NOTIFICATION,
  payload: { notificationValues },
});

export const fetchRetailersList = (currentPage, pageLimit) => ({
  type: FETCH_RETAILERS_LIST,
  payload: { currentPage, pageLimit },
});

export const fetchRetailersListFail = (message) => ({
  type: FETCH_RETAILERS_LIST_FAIL,
  payload: { message },
});

export const fetchRetailersListSuccess = (
  retailers,
  totalPages,
  currentpage
) => ({
  type: FETCH_RETAILERS_LIST_SUCCESS,
  payload: { retailers, totalPages, currentpage },
});

export const fetchRetailersOfDistributors = (currentPage, pageLimit) => ({
  type: FETCH_RETAILER_UNDER_DISTRIBUTOR,
  payload: { currentPage, pageLimit },
});

export const fetchRetailersOfDistributorFails = (message) => ({
  type: FETCH_RETAILER_UNDER_DISTRIBUTOR_FAIL,
  payload: { message },
});

export const fetchRetailerOfDistributorSuccess = (
  retailersUnderDistributor,
  totalPages,
  currentpage
) => ({
  type: FETCH_RETAILER_UNDER_DISTRIBUTOR_SUCCESS,
  payload: { retailersUnderDistributor, totalPages, currentpage },
});
export const fetchRetailerWallet = (walletBalance) => ({
  type: FETCH_RETAILERS_WALLET,
  payload: { walletBalance },
});

export const fetchRetailerProfileImage = (profileImage) => ({
  type: FETCH_RETAILER_PROFILE_IMAGE,
  payload: { profileImage },
});

export const fetchPaymentList = (currentPage, pageLimit) => ({
  type: FETCH_RETAILER_PAYMENT_LIST,
  payload: { currentPage, pageLimit },
});

export const fetchPaymentListFail = (message) => ({
  type: FETCH_RETAILER_PAYMENT_LIST_FAIL,
  payload: { message },
});

export const fetchPaymentListSuccess = (
  paymentList,
  totalPages,
  currentpage
) => ({
  type: FETCH_RETAILER_PAYMENT_LIST_SUCCESS,
  payload: { paymentList, totalPages, currentpage },
});

export const addPayment = (paymentValues) => ({
  type: RETAILER_ADD_PAYMENT,
  payload: { paymentValues },
});

export const addPaymentFail = (message) => ({
  type: RETAILER_ADD_PAYMENT_FAIL,
  payload: { message },
});

export const addPaymentSuccess = (message) => ({
  type: RETAILER_ADD_PAYMENT_SUCCESS,
  payload: { message },
});

export const fetchDisputeList = (currentPage, pageLimit) => ({
  type: FETCH_RETAILER_DISPUTE_LIST,
  payload: { currentPage, pageLimit },
});

export const fetchDisputeListFail = (message) => ({
  type: FETCH_RETAILER_DISPUTE_LIST_FAIL,
  payload: { message },
});

export const fetchDisputeListSuccess = (
  disputeList,
  totalPages,
  currentpage
) => ({
  type: FETCH_RETAILER_DISPUTE_LIST_SUCCESS,
  payload: { disputeList, totalPages, currentpage },
});

export const fetchDisputeDetails = (disputeId) => ({
  type: FETCH_RETAILER_DISPUTE_DETAIL,
  payload: { disputeId },
});

export const fetchDisputeDetailsFail = (message) => ({
  type: FETCH_RETAILER_DISPUTE_DETAIL_FAIL,
  payload: { message },
});

export const fetchDisputeDetailsSuccess = (disputeDetail) => ({
  type: FETCH_RETAILER_DISPUTE_DETAIL_SUCCESS,
  payload: { disputeDetail },
});

export const addRetailerDispute = (retailerDisputeValues) => ({
  type: ADD_RETAILER_DISPUTE,
  payload: { retailerDisputeValues },
});

export const addRetailerDisputeFail = (message) => ({
  type: ADD_RETAILER__DISPUTE_FAIL,
  payload: { message },
});

export const addRetailerDisputeSuccess = (message) => ({
  type: ADD_RETAILER__DISPUTE_SUCCESS,
  payload: { message },
});

export const addRetailerDisputeComment = (retailerCommentValues) => ({
  type: ADD_RETAILER_DISPUTE_COMMENT,
  payload: { retailerCommentValues },
});

export const addRetailerDisputeCommentFail = (message) => ({
  type: ADD_RETAILER__DISPUTE__COMMENT_FAIL,
  payload: { message },
});

export const addRetailerDisputeCommentSuccess = (message) => ({
  type: ADD_RETAILER__DISPUTE__COMMENT_SUCCESS,
  payload: { message },
});
